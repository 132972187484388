import React, { useState, useEffect } from "react";

import Modal from "react-bootstrap/Modal";
import lipstick from "../../assets/Home/newlip.webp";
import lipliner from "../../assets/Home/newliner.webp";
import lipgloss from "../../assets/Home/newgloss.webp";
import lipstain from "../../assets/Home/newstain.webp";
import face1 from "../../assets/Home/28.webp";
import face2 from "../../assets/Home/29.webp";
import face3 from "../../assets/Home/22trans.webp";
import face4 from "../../assets/Home/9.webp";
import UploadDemoImageModal from "./UploadDemoImageModal";
import shapewear from "../../assets/Home/5.webp";
import HairPressed from "../../assets/Home/hairpressed.webp";
import HairStyle from "../../assets/Home/demohair.webp";
import contourImg from "../../assets/Home/contour.webp";
import bronzer from "../../assets/Home/bronzer.avif";
import shoe from "../../assets/Home/nude.webp";
import blush from "../../assets/Home/blush.avif";

import { AiOutlineClose } from "react-icons/ai";
import FoundationConcealerSkinTintTryDemoModal from "./FoundationConcealerSkinTintTryDemoModal";

import Swal from "sweetalert2";

const ComingSoonAlert = () => {
  Swal.fire({
    text: `Coming Soon`,
    showCloseButton: true,
    confirmButtonColor: "#9B6644",
    cancelButtonColor: "#827B75",
    showConfirmButton: false,
    showCancelButton: true,
    focusConfirm: false,
    cancelButtonText: '<i class="fa fa-thumbs-down"></i> Ok',
    background: "#EEE1D4",
  });
};

const ProductsCard = ({ product }) => {
  const checkandgetFunction = () => {
    if (product.id === 3) {
      ComingSoonAlert();
    }
    if (product.id === 4) {
      ComingSoonAlert();
    }
  };
  return (
    <button
      className="demoProductsOpenedCard"
      onClick={checkandgetFunction}
      style={{ border: "none", padding: 0, margin: 0 }}
    >
      <img
        src={product.imageUrl}
        alt={product.name}
        className="img-fluid demoproductimgs"
        style={{
          filter:
            product.id === 3 || product.id === 4 ? "grayscale(100%)" : "none",
        }}
      />
      <div className="demo-text-overlay">{product.name}</div>
    </button>
  );
};

const LIpsProducts = [
  {
    id: 1,
    name: "Lipstick",
    imageUrl: lipstick,
    description: "Lipstick",
    apiendpoint: "https://robomua-lips-de4302239dd1.herokuapp.com/lipstick",
  },

  {
    id: 2,
    name: "Lip Liner",
    imageUrl: lipliner,
    description: "Lip Liner",
    apiendpoint: "https://robomua-lipliner-a44bcef8070c.herokuapp.com/lipliner",
  },

  {
    id: 3,
    name: "Lip gloss",
    imageUrl: lipgloss,
    description: "Lip Gloss",
    apiendpoint: "https://robomua-api.herokuapp.com/qb_lipstick",
  },

  {
    id: 4,
    name: "Lipstick & Lip Liner Combo",
    imageUrl: lipstain,
    description: "Lipstick & Lip Linear Combo",
    apiendpoint: "https://robomua-api.herokuapp.com/qb_lipstick",
  },
];

const ShoesProducts = [
  {
    id: 5,
    name: "Nude Shoes",
    imageUrl: shoe,
    description: "Nude shoes",
    apiendpoint: "https://robomua-fashion-api.herokuapp.com/nude_shoes",
  },
];

const CheeksProducts = [
  {
    id: 6,
    name: "Bronzer",
    imageUrl: bronzer,
    description: "Bronzer",
    apiendpoint: 'https://robomua-api.herokuapp.com/bronzer_predict"',
  },

  {
    id: 7,
    name: "Contour",
    imageUrl: contourImg,
    description: "Contour",
    apiendpoint: "https://robomua-api.herokuapp.com/contour_predict",
  },

  {
    id: 8,
    name: "Blush",
    imageUrl: blush,
    description: "Blush",
    apiendpoint: " https://robomua-blush-e3801b294da7.herokuapp.com/blush",
  },
];

const FaceProducts = [
  {
    id: 9,
    name: "Foundation",
    imageUrl: face1,
    description: "Lotion",
    apiendpoint: "https://robomua-api.herokuapp.com/test_predict",
  },
  {
    id: 10,
    name: "Skin Tint",
    imageUrl: face2,
    description: "Skin Tint",
    apiendpoint: "https://robomua-api.herokuapp.com/skintint_predict",
  },

  {
    id: 11,
    name: "Concealer",
    imageUrl: face3,
    description: "Concealer",
    apiendpoint: "https://robomua-api.herokuapp.com/concealer_predict",
  },

  {
    id: 12,
    name: "Setting Powder",
    imageUrl: face4,
    description: "Setting Powde",
    apiendpoint: "https://robomua-api.herokuapp.com/settingpowder_predict",
  },
];

const ShapewearProducts = [
  {
    id: 13,
    name: "Shapewear",
    imageUrl: shapewear,
    description: "Shapewear",
    apiendpoint: "https://robomua-fashion-api.herokuapp.com/shapewear",
  },
];

const HairProducts = [
  {
    id: 14,
    name: "Hair Color Try-on",
    imageUrl: HairPressed,
    description: "Shapewear",
    apiendpoint: " https://robomuahair-b070bb2bb3a8.herokuapp.com/hairdye",
  },

  {
    id: 15,
    name: "Hair Style Try-on",
    imageUrl: HairStyle,
    description: "HairStyle",
    apiendpoint:
      "https://robomua-hairstyle-b3bfbd2af446.herokuapp.com/api/hair",
  },
];

export default function DemoProductsOpened({
  handleHideFaceAndLipsModal,
  DemoProductChosen,
  handleHideDemoProductsOpenendModal,
  DemoProductsOpenedModal,
}) {
  const [DemoProductName, setDemoProductName] = useState("");
  const [DemoProductImage, setDemoProductImage] = useState("");
  const [ProductApi, setProductApi] = useState("");
  const [ProductDescription, setProductDescription] = useState("");
  const [ProductToUseForMap, setProductToUseForMap] = useState([]);

  const [
    ShowFoundationConcealerSkinTintTryDemoModal,
    setShowFoundationConcealerSkinTintTryDemoModal,
  ] = useState(false);
  const [showEachDemoPageModal, setShowEachDemoPageModal] = useState(false);
  const [showLipsHairDemoModal, setShowLipsHairDemoModdal] = useState(false);

  const handleHideFoundationConcealerSkinTintTryDemoModal = () =>
    setShowFoundationConcealerSkinTintTryDemoModal(false);
  const handleShowFoundationConcealerSkinTintTryDemoModal = () =>
    setShowFoundationConcealerSkinTintTryDemoModal(true);
  const handleShowEachDemoPageModal = () => setShowEachDemoPageModal(true);
  const handleHideEachDemoPageModal = () => setShowEachDemoPageModal(false);
  const handleShowLipsHairModal = () => setShowLipsHairDemoModdal(true);

  const hideLipsDemoModal = () => setShowLipsHairDemoModdal(false);

  const closeHideFaceAndModal = () => {
    handleHideFaceAndLipsModal();
  };

  const handleProductPressed = (name, image, api, description, id) => {
    setDemoProductName(name);
    setDemoProductImage(image);
    setProductApi(api);
    setProductDescription(description);

    if (
      name === "Lipstick" ||
      name == "Lip Liner" ||
      name == "Blush" ||
      // name === "Lipstick & Lip Liner Combo" ||
      name === "Hair Color Try-on" ||
      name === "Hair Style Try-on"
    ) {
      handleShowLipsHairModal();
    } else if (
      DemoProductChosen === "Face" ||
      DemoProductChosen === "Shoes" ||
      DemoProductChosen === "Cheeks" ||
      DemoProductChosen === "Shapewear"
    ) {
      handleShowFoundationConcealerSkinTintTryDemoModal();
    }
  };

  const whichArrayTouseFunction = () => {
    if (DemoProductChosen === "Lips") {
      setProductToUseForMap(LIpsProducts);
    } else if (DemoProductChosen === "Face") {
      setProductToUseForMap(FaceProducts);
    } else if (DemoProductChosen === "Cheeks") {
      setProductToUseForMap(CheeksProducts);
    } else if (DemoProductChosen === "Shoes") {
      setProductToUseForMap(ShoesProducts);
    } else if (DemoProductChosen === "Shapewear") {
      setProductToUseForMap(ShapewearProducts);
    } else if (DemoProductChosen === "Hair") {
      setProductToUseForMap(HairProducts);
    }
  };

  useEffect(() => {
    whichArrayTouseFunction();
  }, [DemoProductChosen]);

  return (
    <div className="">
      <Modal
        show={DemoProductsOpenedModal}
        onHide={closeHideFaceAndModal}
        size="lg"
        backdrop="static"
        keyboard={"false"}
      >
        <Modal.Body
          style={{
            backgroundColor: "#EEE1D4",
            minHeight: "77vh",
          }}
        >
          <div>
            <div className="Choose-Product-Div">
              <Modal.Header style={{ border: "none" }}>
                <AiOutlineClose
                  color="#9B6644"
                  onClick={closeHideFaceAndModal}
                  size={23}
                />
              </Modal.Header>

              <span className="Choose-Product">Choose Product</span>

              <div></div>
            </div>

            <div className="demo-card-div">
              {ProductToUseForMap.map((product) => (
                <div
                  onClick={() =>
                    handleProductPressed(
                      product.name,
                      product.imageUrl,
                      product.apiendpoint,
                      product.description,
                      product.id
                    )
                  }
                >
                  <ProductsCard key={product.name} product={product} />
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <FoundationConcealerSkinTintTryDemoModal
        ShowFoundationConcealerSkinTintTryDemoModal={
          ShowFoundationConcealerSkinTintTryDemoModal
        }
        handleHideFoundationConcealerSkinTintTryDemoModal={
          handleHideFoundationConcealerSkinTintTryDemoModal
        }
        ProductNameFromOtherComponent={DemoProductName}
        ProductNameFromOtherComponentImage={DemoProductImage}
        handleHideDemoProductsOpenendModal={handleHideDemoProductsOpenendModal}
      />

      <UploadDemoImageModal
        showLipsHairDemoModal={showLipsHairDemoModal}
        hideLipsDemoModal={hideLipsDemoModal}
        ProductNameFromOtherComponent={DemoProductName}
        ProductNameFromOtherComponentImage={DemoProductImage}
        DemoProductChosen={DemoProductChosen}
        ProductApi={ProductApi}
        DemoProductName={DemoProductName}
      />
    </div>
  );
}
