import { CiLaptop } from "react-icons/ci";
import { CiMobile1 } from "react-icons/ci";
import { AiOutlineChrome } from "react-icons/ai";
import MobilePage from "./MobilePage";
import Webpage from "./Webpage";
import ChromePage from "./Chrome";

export const allIngredients = [
  {
    icon: CiLaptop,
    label: "WEBSITE",
    ProductPage: Webpage,
  },
  {
    icon: CiMobile1,
    label: "MOBILE APP",
    ProductPage: MobilePage,
  },
  {
    icon: AiOutlineChrome,
    label: "CHROME EXTENSION",
    ProductPage: ChromePage,
  },
];

const [website, mobileapp, chrome] = allIngredients;
export const initialTabs = [website, mobileapp, chrome];
