import React from "react";
import androiddownload from "../../assets/untitled folder/downloadAndroid.webp";
import appledownload from "../../assets/untitled folder/downloadApple.webp";
// import demoVideo from "../../../assets/untitled folder/demo_screen.mp4";
import demoVideo from "../../assets/Home/yShade_Demo.mp4";
import phoneImg from "../../assets/untitled folder/iphone_frame.webp";
import "./products.css";

export default function MobilePage() {
  return (
    <div>
      <div className="container px-5 hero-main">
        <div className="row gx-5 align-items-center robomua-hero-main">
          <div className="col-lg-6">
            <div className="hero-main-text">
              <h1 className="hero-main-text">
                Your Personal <br /> Makeup Artist <br /> is Here!
              </h1>
            </div>
            <div>
              <p>
                Welcome to roboMUA! We're a cutting-edge company that uses
                artificial intelligence to help you find the perfect beauty
                products for your unique skin shade. Our mobile app makes it
                easy to get personalized product recommendations, save and track
                your favorite products, and even shop for products directly
                within the app.
              </p>
            </div>

            <div className="download-btns-div">
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.domainname.roboMUANEW"
                  target="_blank"
                  aria-label="link to download robomua app on android"
                >
                  <img
                    src={androiddownload}
                    alt="roboMUA APP demo Iphone frame"
                    className="download-flyer"
                  />
                </a>
              </div>
              <div>
                <a
                  href="https://apps.apple.com/us/app/robomua/id6443639738"
                  aria-label="link to download robomua app on ios"
                  target="_blank"
                >
                  <img src={appledownload} className="download-flyer" />
                </a>
              </div>
            </div>
          </div>

          <div className="img-video-div col-lg-6">
            {/* <img src={phoneImg} className="iphone-frame-hero" /> */}
            <video
              aria-label="roboMUA APP demo"
              muted
              loop
              autoPlay
              playsInline
              className="phone-video-hero"
            >
              <source src={demoVideo} className="phonevideo" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  );
}
