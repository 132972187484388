import React from "react";
import { FaInstagram } from "react-icons/fa";
import { CiFacebook, CiTwitter } from "react-icons/ci";
import { FiLinkedin, FiFacebook } from "react-icons/fi";
import { FaXTwitter } from "react-icons/fa6";
import logo from "../../../assets/Home/yshadelogobig.png";
import { Link } from "react-router-dom";

export default function Footer() {
  const getCurrentYear = () => new Date().getFullYear();
  return (
    <footer>
      <div className="footer-main">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12 m-md-auto align-self-center">
              <div className="block">
                <a href="/">
                  <img src={logo} alt="footer-logo" />
                </a>
                <ul className="social-icon list-inline">
                  <li className="list-inline-item">
                    <a
                      href="https://www.linkedin.com/company/robomua/?viewAsMember=true"
                      target="_blank"
                    >
                      <FiLinkedin size={23} />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.facebook.com/robomua" target="_blank">
                      <FiFacebook size={23} />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://x.com/robo_mua" target="_blank">
                      <FaXTwitter size={23} />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/robomua/"
                      target="_blank"
                    >
                      {" "}
                      <FaInstagram size={23} />{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-6 mt-5 mt-lg-0">
              <div className="block-2">
                <h6>Product</h6>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href="https://docs.robomua.com/"
                      className="footer-links"
                    >
                      APIs
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="/robopage"
                      className="footer-links"
                    >
                      roboMUA
                    </a>
                  </li>
                  <li>
                    <a href="#" className="footer-links">
                      yShade100
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-6 mt-5 mt-lg-0">
              <div className="block-2">
                <h6>Resources</h6>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href="https://robomua.medium.com/"
                      className="footer-links"
                    >
                      Blog
                    </a>
                  </li>
                  <li>
                    <a href="/terms" className="footer-links">
                      Terms
                    </a>
                  </li>
                  <li>
                    <a href="/privacy-policy" className="footer-links">
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-6 mt-5 mt-lg-0">
              <div className="block-2">
                <h6>Company</h6>
                <ul>
                  <li>
                    <a href="/faqs" className="footer-links">
                      FAQs
                    </a>
                  </li>
                  {/* <li>
                    <a href="/careers" className="footer-links">
                      Careers
                    </a>
                  </li> */}
                  <li>
                    <a className="footer-links" href="/contactUs">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center footer-sub py-4">
        <small>
          Copyright &copy; {getCurrentYear()}. All Rights Reserved. yShade.AI{" "}
        </small>
      </div>
    </footer>
  );
}
