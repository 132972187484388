import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// Define the job details as objects

function ModalCareers({ show, currentJob, handleClose }) {
  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{currentJob?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="modal-careers-body">
            <div>
              <strong>Location:</strong>
            </div>
            <div>{currentJob?.location}</div>
          </div>
          <br />
          <div className="modal-careers-body">
            <div>
              <strong>About You:</strong>
            </div>
            <div>{currentJob?.description}</div>
          </div>
          <br />
          <div className="modal-careers-body">
            <div>
              <strong>Requirements:</strong>
            </div>
            <ul>
              {currentJob?.requirements.map((requirement, index) => (
                <li key={index}>{requirement}</li>
              ))}
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div class="form-title"></div>
          <form action="https://getform.io/f/zazkzvdb" method="POST">
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <input
                  class="form-control main"
                  type="text"
                  placeholder="Name"
                  required
                  name="Name"
                />
              </div>
              <div class="col-lg-6 col-md-12">
                <input
                  class="form-control main"
                  type="email"
                  placeholder="Email Address"
                  required
                  name="Email"
                />
              </div>
              <div class="col-lg-6 col-md-12">
                <input
                  class="form-control main"
                  type="text"
                  placeholder="Portfolio/Resume Link"
                  required
                  name="Portfolio/Resume Link"
                />
              </div>
              <div class="col-lg-6 col-md-12">
                <input
                  class="form-control main"
                  type="text"
                  placeholder="Github/Stackoverflow Link"
                  required
                  name="Github/Stackoverflow Link"
                />
              </div>
              <div class="col-md-12">
                <textarea
                  class="form-control main"
                  name="Why candidate is a fit at Yshadeai:"
                  id=""
                  rows="10"
                  placeholder="Tell Us Why You'll Fit in at yShade"
                ></textarea>
              </div>
              <div class="col-12 text-right">
                <button class="btn btn-main-md" type="submit">
                  Apply Now
                </button>
              </div>
            </div>
          </form>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalCareers;
