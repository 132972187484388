import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { AiOutlineClose, AiOutlineShoppingCart } from "react-icons/ai";

export default function FoundationVirtual({
  ShowFoundationVirtualTryon,
  handleHideFoundationVirtualTryonModal,
  FoundationVirtualData,
  data,
  uploadedImage,
  CompanyNameState,
  FoundationCodeState,
  handleGoBack,
}) {
  const [virtualIsloading, setisVirtualLoading] = useState(false);
  const [NewResultsFromApi, setNewResultsFromApi] = useState("");
  const [companyNameFromFoundation, setCompanyNameFromFoundation] = useState();
  const [FoundationCodeFromFoundation, setFoundationCodeFromFoundation] =
    useState();
  const [FoundationIsApplied, setFoundationIsApplied] = useState(false);
  const [ProductLink, setProductLink] = useState("");

  const hideModal = () => {
    handleHideFoundationVirtualTryonModal();
    setFoundationIsApplied(false);
    handleGoBack();
  };

  const getVirtualDataForFoundation = (colorSelected, company, code, link) => {
    setCompanyNameFromFoundation(company);
    setFoundationCodeFromFoundation(code);

    setisVirtualLoading(true);
    let form = new FormData();
    form.append("file", uploadedImage);
    form.append("color", colorSelected);

    axios
      .post(
        "https://robomua-foundation-6b77b5e3047c.herokuapp.com/foundation",
        form
      )
      .then((results) => {
        setisVirtualLoading(false);
        setNewResultsFromApi(results.data);
        setFoundationIsApplied(true);
        setProductLink(link);
      })
      .catch((error) => {
        setisVirtualLoading(false);
        alert(
          "Error!! Please use a clearer image or check your internet connectivity",
          error.message
        );
      });
  };

  return (
    <div style={{}}>
      <Modal
        show={ShowFoundationVirtualTryon}
        onHide={hideModal}
        size="md"
        backdrop="static"
        keyboard={"false"}
        style={{ backgroundColor: "#43403D", padding: 0 }}
      >
        <Modal.Body
          style={{
            backgroundColor: "#EEE1D4",
            minHeight: "70vh",
            justifyContent: "center",
            padding: 0,
          }}
        >
          <a
            style={{
              position: "absolute",
              padding: "1rem",
              left: 0,
              zIndex: 999,
            }}
          >
            <AiOutlineClose color="#9D6948" onClick={hideModal} size={30} />
          </a>

          <a
            target="_blank"
            onClick={() => {
              ProductLink === undefined
                ? alert("Please Choose A Foundation Brand")
                : window.open(ProductLink, "_blank");
            }}
            style={{
              position: "absolute",
              right: 0,
              zIndex: 999,
              padding: "1rem",
            }}
          >
            <AiOutlineShoppingCart color="#9D6948" size={30} />
          </a>

          <div>
            <div>
              <img
                src={
                  NewResultsFromApi
                    ? `data:image/jpeg;base64,${NewResultsFromApi.b64}`
                    : `data:image/jpeg;base64,${FoundationVirtualData.b64}`
                }
                alt=""
                className="fentyvirtualImage img-fluid"
                style={{
                  filter: virtualIsloading && "blur(5px)",
                }}
              />

              {virtualIsloading && (
                <span
                  style={{
                    color: "white",
                    position: "absolute",
                    top: "10%",
                    fontSize: "1.5rem",
                    textAlign: "center",
                    width: "20rem",
                    top: "10%",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  {" "}
                  <img
                    style={{ width: "1.5rem", marginTop: "1rem" }}
                    src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif"
                  />{" "}
                  <br />
                  Applying Foundation {companyNameFromFoundation}{" "}
                  {FoundationCodeFromFoundation} color to your Face
                </span>
              )}
            </div>

            {FoundationIsApplied ? (
              <h6
                className="foundationvirtualcompanyheading"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  margin: "2.5rem",
                  borderRadius: "10px",
                }}
              >
                {" "}
                <span
                  style={{
                    color: "#9D6948",
                    backgroundColor: "#DDB892",
                    padding: "0.5rem",
                    borderRadius: "10px",
                  }}
                >
                  {companyNameFromFoundation} {FoundationCodeFromFoundation}{" "}
                </span>
              </h6>
            ) : (
              <h6
                className="foundationvirtualcompanyheading"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  margin: "2.5rem",
                  borderRadius: "10px",
                }}
              >
                {" "}
                <span
                  style={{
                    color: "#9D6948",
                    backgroundColor: "#DDB892",
                    padding: "1rem",
                    borderRadius: "10px",
                  }}
                >
                  {CompanyNameState} {FoundationCodeState}
                </span>
              </h6>
            )}

            <div
              style={{
                display: "flex ",
                overflowX: "scroll",
                maxWidth: "100%",
                padding: "0.5rem",
              }}
              className="foundation-virtual-differetn-shades-div"
            >
              {data?.map((each_one) => {
                return (
                  <div
                    style={{
                      padding: "2rem  ",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                    onClick={() =>
                      getVirtualDataForFoundation(
                        each_one.HEXColor,
                        each_one.Company,
                        each_one.Foundation,
                        each_one.ProductURL
                      )
                    }
                  >
                    <p style={{ flex: 1, color: "#9D6948" }}>
                      {each_one.Company}
                    </p>
                    <p style={{ flex: 1, color: "#9D6948" }}>
                      {each_one.Foundation}
                    </p>
                    <p
                      style={{
                        height: 50,
                        width: 50,
                        backgroundColor: each_one.HEXColor,
                        borderRadius: "100px",
                        flex: "0 0 auto",
                      }}
                    ></p>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
