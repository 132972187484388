import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import spanCollageImg from "../../assets/collageface.webp";

export default function GotYou() {
  const titleVariant = {
    slideInFromRight: {
      opacity: 1,
      transition: {
        duration: 10,
      },
    },
  };

  return (
    <section class="feature  pb-20" style={{ marginBottom: "4rem" }}>
      <div class="container">
        <div class="row">
          <div class="col-lg-6 mr-auto justify-content-center">
            <div class="image-content" data-aos="fade-left">
              <img class="img-fluid" src={spanCollageImg} alt="face-collage" />
            </div>
          </div>
          <div class="col-lg-6 mr-auto align-self-center">
            <div class="feature-content">
              <h2>We Got You Covered</h2>
              <p class="desc">
                Spanning from the lightest porcelain to the deepest hues, our
                custom industry-defining dataset captures variations in lighting
                and weather conditions, ensuring comprehensive representation.
              </p>
            </div>
            <div class="testimonial">
              <p>
                Experience our innovative predictive and generative AI models
                designed specifically with all skin shades in mind.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
