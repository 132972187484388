import React from "react";
import Footer from "../components/General/Footer/Footer";
import NavigationBar from "../components/General/Navbar/NavigationBar";
import "../App.css";

const Terms = () => {
  return (
    <>
      <NavigationBar />
      <section className="privacy section pt-0">
        <div className="container terms-header-div">
          <h1>TERMS OF SERVICE</h1>
          <p>
            By using our services, you agree to these terms, which govern your
            use of our website, products, and services. <br />
            Please read them carefully, as they contain important information
            about your rights and obligations.
          </p>

          <div className="row">
            <div className="col-lg-3">
              <nav className="privacy-nav">
                <ul>
                  <li>
                    <a className="nav-link scrollTo" href="#acceptanceTerms">
                      Acceptance of Terms
                    </a>
                  </li>
                  <li>
                    <a className="nav-link scrollTo" href="#useServices">
                      Use of Services
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link scrollTo"
                      href="#intellectualProperty"
                    >
                      Intellectual Property
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link scrollTo"
                      href="#disclaimersLimitations"
                    >
                      Disclaimers and Limitations
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-lg-9">
              <div className="block">
                <div id="acceptanceTerms" className="policy-item">
                  <div className="title">
                    <h3>Acceptance of Terms</h3>
                  </div>
                  <div className="policy-details">
                    <p>
                      By using our services, you agree to be bound by these
                      Terms and Conditions...
                    </p>
                    <p>
                      If you do not agree to these Terms, please do not use our
                      services...
                    </p>
                  </div>
                </div>
                <div id="useServices" className="policy-item">
                  <div className="title">
                    <h3>Use of Services</h3>
                  </div>
                  <div className="policy-details">
                    <p>
                      Our services are intended for individuals 18 years of age
                      or older...
                    </p>
                    <p>
                      You agree to use our services in compliance with all
                      applicable laws...
                    </p>
                  </div>
                </div>
                <div id="intellectualProperty" className="policy-item">
                  <div className="title">
                    <h3>Intellectual Property</h3>
                  </div>
                  <div className="policy-details">
                    <p>
                      Our website, products, and services contain proprietary
                      and confidential information...
                    </p>
                    <p>
                      All trademarks, logos, and service marks displayed on our
                      website are our property...
                    </p>
                  </div>
                </div>
                <div id="disclaimersLimitations" className="policy-item">
                  <div className="title">
                    <h3>Disclaimers and Limitations</h3>
                  </div>
                  <div className="policy-details">
                    <p>
                      We provide our services on an "as is" and "as available"
                      basis...
                    </p>
                    <p>
                      In no event will we be liable for any direct, indirect,
                      incidental, special, or consequential damages...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Terms;
