import React from "react";
import NavigationBar from "../components/General/Navbar/NavigationBar";
import Footer from "../components/General/Footer/Footer";
import { CiMobile4 } from "react-icons/ci";
import { FaRegEnvelope } from "react-icons/fa";

export default function ContactUs() {
  return (
    <div>
      <NavigationBar />
      <div>
        <section className="page-title">
          <div className="container">
            <div className="row">
              <div className="col-sm-8 m-auto header-div">
                <h1>Contact Us</h1>
                <p>
                  We'd love to hear from you! Whether you have a question, a
                  comment, or just want to say hello, please don't hesitate to
                  reach out - we're always here to help.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="address">
          <div className="container">
            <div className="row">
              {/* <div className="col-lg-6 align-self-center">
                <div className="block">
                  <div className="address-block text-center mb-5">
                    <div className="icon">
                      <i className="ti-email contact-icon">
                        <CiMobile4 size={40} className="hang-icon" />
                      </i>
                    </div>
                    <div className="details">
                      <h3>+1 (502) 405-8054</h3>
                      <h3>+1 (828) 460-5394</h3>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-12 align-self-center">
                <div className="block">
                  <div className="address-block text-center mb-5">
                    <div className="icon">
                      <i className="ti-email contact-icon">
                        <FaRegEnvelope size={40} className="hang-icon" />
                      </i>
                    </div>
                    <div className="details">
                      <h3>info@robomua.com</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-form">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="mb-5 text-center header">Get In Touch</h2>
              </div>
              <div className="col-12">
                <form action="https://getform.io/f/zazkzvdb" method="POST">
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <input
                        className="form-control main"
                        type="text"
                        placeholder="Name"
                        required
                        name="Name"
                      />
                    </div>
                    <div className="col-md-6 mb-2">
                      <input
                        className="form-control main"
                        type="email"
                        placeholder="Your Email Address"
                        required
                        name="Email"
                      />
                    </div>
                    <div className="col-md-12 mb-2">
                      <input
                        className="form-control main"
                        type="text"
                        placeholder="Subject"
                        required
                        name="Subject"
                      />
                    </div>
                    <div className="col-md-12 mb-2">
                      <textarea
                        className="form-control main"
                        name="message"
                        rows="10"
                        placeholder="Your Message"
                      ></textarea>
                    </div>
                    <div className="col-12 text-right">
                      <button className="btn btn-main-md" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
}
