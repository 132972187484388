import React, { useState } from "react";
import ModalCareers from "./ModalCareers";
import Button from "react-bootstrap/Button";

const jobs = [
  {
    title: "AI/ML Engineer",
    location: "Remote",
    description:
      "Leverage your expertise in machine learning to drive innovation and set new standards for AI technology at yShade. You'll not only develop cutting-edge models and algorithms but also effectively communicate complex technical concepts to cross-functional teams and stakeholders.",
    requirements: [
      "2+ years of experience in AI/ML development, preferably in a startup environment.",
      "Strong foundation in machine learning, deep learning, and data science.",
      "Proficiency in programming languages such as Python, TensorFlow, or PyTorch.",
      "Excellent problem-solving skills, with the ability to manage milestones, deadlines, and priorities with ease.",
    ],
  },

  {
    title: "DevOps Engineer",
    location: "Remote",
    description:
      "Drive the adoption of cutting-edge cloud technologies and lead the charge in streamlining our infrastructure and workflows. With a passion for automation, security, and collaboration, you'll bridge the gap between development and operations, ensuring the smooth operation of our AI technology.",
    requirements: [
      "2+ years of experience in DevOps or a related field, preferably in a startup environment.",
      "Strong expertise in cloud platforms (AWS, GCP, Azure), containerization (Docker), and orchestration tools (Kubernetes).",
      "Proficiency in scripting languages (Python, Bash) and automation tools (Ansible, Terraform).",
      "Excellent problem-solving skills, with the ability to manage milestones, deadlines, and priorities with ease.",
    ],
  },

  {
    title: "Data Annotation Engineer",
    location: "Remote",
    description:
      "Join our team as a Data Annotation Engineer and play a crucial role in preparing high-quality training data for our AI models. With a keen eye for detail and a passion for data accuracy, you'll lead the charge in developing and implementing efficient annotation processes, ensuring our AI technology is trained on the best possible data.",
    requirements: [
      "2+ years of experience in data annotation, data engineering, or a related field, preferably in a startup environment",
      "Strong understanding of data structures, data modeling, and data processing pipelines",
      "Proficiency in programming languages such as Python, SQL, and JavaScript",
      "Excellent problem-solving skills, with the ability to manage milestones, deadlines, and priorities with ease",
    ],
  },
];

export default function CareerJobs() {
  const [show, setShow] = useState(false);
  const [currentJob, setCurrentJob] = useState(null);

  const handleViewDetailsClicket = (jobtype) => {
    const job = jobs.find((jobItem) => jobItem.title === jobtype);
    if (job) {
      setCurrentJob(job);
    }
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <section class="job-list section pt-0">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-10 m-auto">
            <div class="block">
              <div class="title text-center">
                <h2>
                  Job Openings <br />
                  [Coming Soon]
                </h2>
              </div>
              <div class="job">
                <div class="content">
                  <h3>AI/ML Engineer</h3>
                  <p>Remote</p>
                </div>
                <button
                  className="btn-main-md"
                  onClick={() => handleViewDetailsClicket("AI/ML Engineer")}
                >
                  View Details
                </button>
              </div>
              <div class="job">
                <div class="content">
                  <h3>DevOps Engineer</h3>
                  <p>Remote</p>
                </div>
                <button
                  className="btn-main-md"
                  onClick={() => handleViewDetailsClicket("DevOps Engineer")}
                >
                  View Details
                </button>
              </div>
              <div class="job">
                <div class="content">
                  <h3>Data Annotation Engineer</h3>
                  <p>Remote</p>
                </div>
                <button
                  className="btn-main-md"
                  onClick={() =>
                    handleViewDetailsClicket("Data Annotation Engineer")
                  }
                >
                  View Details
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalCareers
        show={show}
        handleClose={handleClose}
        currentJob={currentJob}
      />
    </section>
  );
}
