import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";
import { motion } from "framer-motion";
import _ from "lodash";

import { FileUploader } from "react-drag-drop-files";

import SortButton from "./SortButton";

// Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";

import { signOut, getAuth } from "firebase/auth";
import { PiUploadSimpleThin } from "react-icons/pi";

import FoundationVirtual from "./FoundationVirtual";
import Select from "react-select";

const fileTypes = ["JPG", "PNG", "JPEG"];
export default function FoundationConcealerSkinTintTryDemoModal({
  ShowFoundationConcealerSkinTintTryDemoModal,
  fullscreen,
  handleHideFoundationConcealerSkinTintTryDemoModal,
  ProductNameFromOtherComponent,
  ProductNameFromOtherComponentImage,
  handleHideDemoProductsOpenendModal,
}) {
  const ref = useRef();

  const [file, setFile] = useState(null);
  const [data, setData] = useState(null);
  const [DataToVirtualTryOn, setDataToVirtualTryOn] = useState(null);
  const [dataFromApI, setDataFromAPI] = useState(null);
  const [dataIsCollected, setDataIsCollected] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [sortedStateInModal, setSortedStateInModal] = useState("All Brands");
  const [CurrentProductApi, setCurrentProductAPI] = useState("");
  const [uploadedImage, setUploadedImage] = useState("");
  const [displayedUploadedImage, setdisplayedUploadedImage] = useState("");
  const [isVirtualLoading, setisVirtualLoading] = useState(false);

  const [ratingModalfullscreen, setratingModalfullscreen] = useState(false);
  const [showRatingModal, setShowRatingModal] = useState(false);

  const handleShowRatingModal = () => setShowRatingModal(true);
  const handleHideRatingModal = () => setShowRatingModal(false);
  const [selectedCompany, setSelectedCompany] = useState("All");

  const [FilteredStateFromSortButton, setFilteredStateFromSortButton] =
    useState([]);

  const [nameofProduct, setNameofProduct] = useState("");
  const handleNameofProduct = () => setNameofProduct("");

  const [FoundationVirtualData, setFoundationVirtualData] = useState("");
  const [ShowFoundationVirtualTryon, setShowFoundationVirtualTryon] =
    useState(false);
  const handleShowFoundationVirtualTryonModal = () =>
    setShowFoundationVirtualTryon(true);
  const handleHideFoundationVirtualTryonModal = () =>
    setShowFoundationVirtualTryon(false);

  const [FoundationCodeState, setFoundationCodeState] = useState("");
  const [CompanyNameState, setCompanyNameState] = useState("");

  const handleImageChange = (file) => {
    setFile(file);
  };

  const fireHideModal = () => {
    handleHideFoundationConcealerSkinTintTryDemoModal(false);
    setUploadedImage("");
    setdisplayedUploadedImage("");
  };

  // horizontal scroll

  const handleFilteredState = (funcfilteredFromSort) => {
    if (FilteredStateFromSortButton !== undefined) {
      setFilteredStateFromSortButton(funcfilteredFromSort);
    }
  };

  const sortedStateInFoundationModal = (sortStateFromSortButton) => {
    setSortedStateInModal(sortStateFromSortButton);
  };

  useEffect(() => {
    if (ProductNameFromOtherComponent === "Foundation") {
      setCurrentProductAPI("https://robomua-api.herokuapp.com/test_predict");
    } else if (ProductNameFromOtherComponent === "Concealer") {
      setCurrentProductAPI(
        "https://robomua-api.herokuapp.com/concealer_predict"
      );
    } else if (ProductNameFromOtherComponent === "Skin Tint") {
      setCurrentProductAPI(
        "https://robomua-api.herokuapp.com/skintint_predict"
      );
    } else if (ProductNameFromOtherComponent === "Setting Powder") {
      setCurrentProductAPI(
        "https://robomua-api.herokuapp.com/settingpowder_predict"
      );
    } else if (ProductNameFromOtherComponent === "Bronzer") {
      setCurrentProductAPI("https://robomua-api.herokuapp.com/bronzer_predict");
    } else if (ProductNameFromOtherComponent === "Contour") {
      setCurrentProductAPI("https://robomua-api.herokuapp.com/contour_predict");
    } else if (ProductNameFromOtherComponent === "Nude Shoes") {
      setCurrentProductAPI(
        "https://robomua-fashion-api.herokuapp.com/nude_shoes"
      );
    } else if (ProductNameFromOtherComponent === "Shapewear") {
      setCurrentProductAPI(
        "https://robomua-fashion-api.herokuapp.com/shapewear"
      );
    }
  }, [ProductNameFromOtherComponent]);

  const getData = (file) => {
    setIsloading(true);

    let form = new FormData();
    // form.append('file', file);
    form.append("file", file);

    axios
      .post(CurrentProductApi, form)
      .then((results) => {
        // setData(results.data)
        const groupedData = _.groupBy(results.data, "Company");

        // Create a new array with unique company names and their respective data
        const newDataArray = Object.keys(groupedData).map((companyName) => ({
          Company: companyName,
          Data: _.uniqBy(groupedData[companyName], "Foundation"),
        }));

        setData(newDataArray);
        setDataToVirtualTryOn(results.data);

        setDataIsCollected(true);
        setIsloading(false);
        setFile("");
      })
      .catch((error) => {
        setIsloading(false);
        alert(
          "Error!! Please use a clearer image or check your internet connectivity",
          error.message
        );
      });
  };

  const handleGoBack = () => {
    setDataIsCollected(false);
  };

  const handleChange = (file) => {
    setUploadedImage(file);
    setdisplayedUploadedImage(URL.createObjectURL(file));
    getData(file);
  };

  const handleSignOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        handleHideFoundationConcealerSkinTintTryDemoModal();
        handleHideDemoProductsOpenendModal();
        setDataIsCollected(false);
        setTimeout(() => {
          alert("You are signed out");
        }, 2000);
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const getVirtualDataForFoundation = (
    HEXColor,
    FoundationCode,
    CompanyName
  ) => {
    setFoundationCodeState(FoundationCode);
    setCompanyNameState(CompanyName);

    setTimeout(() => {
      setisVirtualLoading(true);
    }, 10);

    let form = new FormData();
    form.append("file", uploadedImage);
    form.append("color", HEXColor);
    axios
      .post(
        "https://robomua-foundation-6b77b5e3047c.herokuapp.com/foundation",
        form
      )
      .then((results) => {
        setTimeout(() => {
          handleShowFoundationVirtualTryonModal();
        }, 1000);
        setisVirtualLoading(false);
        setFoundationVirtualData(results.data);

        setFoundationCodeState(FoundationCode);
        setCompanyNameState(CompanyName);
      })
      .catch((error) => {
        setisVirtualLoading(false);
        alert(
          "Error!! Please use a clearer image or check your internet connectivity",
          error.message
        );
      });
  };

  const selectOptions = [
    { value: "All", label: "All Brands" },
    ...(data || []).map((companyData) => ({
      value: companyData.Company,
      label: companyData.Company,
    })),
  ];

  const moveVariant = {
    moveLeftAndRight: {
      x: 20,
      x: -20,

      transition: {
        duration: 1,
        repeat: Infinity,
        repeatType: "reverse",
      },
    },
  };

  const scaleAutomatically = {
    scale1: {
      scale: 1.05,

      transition: {
        delay: 3,
        duration: 1,
        repeat: Infinity,
        repeatType: "reverse",
        type: "spring",
      },
    },
    scale2: {
      scale: 1.05,

      transition: {
        duration: 1,
        delay: 4,
        repeat: Infinity,
        repeatType: "reverse",
        type: "spring",
      },
    },
    scale3: {
      scale: 1.05,

      transition: {
        duration: 1,
        delay: 5,
        repeat: Infinity,
        repeatType: "reverse",
        type: "spring",
      },
    },
  };

  return (
    <div>
      <Modal
        className="modal-lg"
        show={ShowFoundationConcealerSkinTintTryDemoModal}
        fullscreen={fullscreen}
        onHide={fireHideModal}
      >
        {isLoading ? (
          <div className="isloadingDiv">
            <div>
              <img
                style={{ width: "1.5rem", marginTop: "1rem" }}
                src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif"
              />
            </div>

            <p
              style={{
                textAlign: "center",
                fontSize: "1.2rem",
                lineHeight: 1.2,
                color: "white",
              }}
            >
              ...Getting your {ProductNameFromOtherComponent} AI recommendations
            </p>
          </div>
        ) : null}

        <Modal.Body
          style={{
            minHeight: "77vh",
            backgroundColor: "#EEE1D4",
          }}
        >
          {dataIsCollected ? (
            <div className="received-data-div">
              <div className="api-goodies-div">
                <div className="api-details-div">
                  <div className="api-items-div-container">
                    <div className="received-data-heading-rating-div">
                      <div className="received-data-heading-div">
                        <h2 className="received-data-heading">
                          Your
                          <span className="product-name">
                            {ProductNameFromOtherComponent}{" "}
                          </span>{" "}
                          AI recommendations
                        </h2>
                        {/* <Button
                          className="sign-out-btn"
                          onClick={handleSignOut}
                        >
                          Sign Out
                        </Button> */}

                        <Button className="go-back-btn" onClick={handleGoBack}>
                          X
                        </Button>
                      </div>
                      <br />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <motion.button
                          className="rate-us-btn"
                          variants={moveVariant}
                          animate="moveLeftAndRight"
                          onClick={() => {
                            handleShowRatingModal();
                            handleNameofProduct();
                          }}
                        >
                          Rate this AI algorithm
                        </motion.button>
                      </div>

                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {" "}
                        <br />
                        <Select
                          options={selectOptions}
                          value={selectedCompany}
                          onChange={(selectedOption) =>
                            setSelectedCompany(selectedOption)
                          }
                          placeholder="Select Brand"
                        />
                      </div>
                    </div>

                    <>
                      {selectedCompany === "All" ||
                      selectedCompany.value === "All" ? (
                        data?.map((each_item, index) => {
                          return (
                            <div
                              style={{
                                filter: isVirtualLoading && "blur(5px)",
                              }}
                            >
                              <hr style={{ border: "1px solid #AE4E58" }} />

                              <div className="company-name-heading-div">
                                <div className="company-name-heading">
                                  {each_item.Company}
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  justifyContent: "center",
                                }}
                              >
                                {each_item.Data.map((each_item) => {
                                  return (
                                    <div
                                      className="api-items-container"
                                      key={index}
                                    >
                                      <div className="images-div">
                                        <img
                                          className="api-images img-fluid"
                                          src={each_item.Image}
                                          draggable={false}
                                          alt="image"
                                        />
                                      </div>
                                      <div className="shade-price-div">
                                        <p
                                          className="shade"
                                          style={{
                                            display:
                                              ProductNameFromOtherComponent ===
                                              "CONCEALER"
                                                ? "none"
                                                : "block",
                                          }}
                                        >
                                          {each_item.Foundation}
                                        </p>
                                        <p
                                          className="shade"
                                          style={{
                                            display:
                                              ProductNameFromOtherComponent ===
                                              "FOUNDATION"
                                                ? "none"
                                                : "block",
                                          }}
                                        >
                                          {each_item.Concealer}
                                        </p>
                                        <p className="">{each_item.Price}</p>
                                      </div>
                                      <div className="api-btns-div">
                                        <a
                                          href={each_item.ProductURL}
                                          target={"_blank"}
                                        >
                                          <motion.button
                                            variants={scaleAutomatically}
                                            whileHover={{
                                              scale: 1.05,
                                            }}
                                            className="api-btns buy-btn"
                                          >
                                            Buy
                                          </motion.button>
                                        </a>

                                        {
                                          // ProductNameFromOtherComponent === "Foundation" ?
                                          //     <a
                                          //         target={"_blank"}>
                                          //         <motion.button
                                          //             variants={scaleAutomatically}
                                          //             onClick={() => (getVirtualDataForFoundation(
                                          //                 each_item.HEXColor,
                                          //                 each_item.Foundation,
                                          //                 each_item.Company
                                          //             ))}
                                          //             whileHover={{
                                          //                 scale: 1.05
                                          //             }}
                                          //             className='api-btns virtual-btn'
                                          //         >Virtual Try On</motion.button>
                                          //     </a> :
                                          //     <a href={each_item.InstagramFilter}
                                          //         target={"_blank"}>
                                          //         <motion.button
                                          //             variants={scaleAutomatically}
                                          //             whileHover={{
                                          //                 scale: 1.05
                                          //             }}
                                          //             className='api-btns virtual-btn'
                                          //         >Virtual Try On</motion.button>
                                          //     </a>
                                        }

                                        <a
                                          href={each_item.VideoTutorial}
                                          target={"_blank"}
                                        >
                                          <motion.button
                                            variants={scaleAutomatically}
                                            whileHover={{
                                              scale: 1.05,
                                            }}
                                            className="api-btns video-btn"
                                          >
                                            Video Tutorial
                                          </motion.button>
                                        </a>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div>
                          <div className="company-name-heading-div ">
                            <div className="company-name-heading">
                              {selectedCompany?.label}
                            </div>
                          </div>

                          <div
                            className=""
                            style={{ filter: isVirtualLoading && "blur(5px)" }}
                          >
                            {data
                              .filter(
                                (companyData) =>
                                  companyData.Company === selectedCompany?.value
                              )
                              .map((companyData) =>
                                companyData.Data.map((each_item, itemIndex) => (
                                  <div
                                    key={itemIndex}
                                    className="api-items-container"
                                  >
                                    {/* Render your data for each item within the selected company here */}
                                    <div className="images-div">
                                      <img
                                        className="api-images img-fluid"
                                        src={each_item.Image}
                                        draggable={false}
                                        alt="image"
                                      />
                                    </div>
                                    <div className="shade-price-div">
                                      <p
                                        className="shade"
                                        style={{
                                          display:
                                            ProductNameFromOtherComponent ===
                                            "CONCEALER"
                                              ? "none"
                                              : "block",
                                        }}
                                      >
                                        {each_item.Foundation}
                                      </p>
                                      <p
                                        className="shade"
                                        style={{
                                          display:
                                            ProductNameFromOtherComponent ===
                                            "FOUNDATION"
                                              ? "none"
                                              : "block",
                                        }}
                                      >
                                        {each_item.Concealer}
                                      </p>
                                      <p className="">{each_item.Price}</p>
                                    </div>

                                    <div className="api-btns-div">
                                      <a
                                        href={each_item.ProductURL}
                                        target={"_blank"}
                                      >
                                        <motion.button
                                          variants={scaleAutomatically}
                                          whileHover={{
                                            scale: 1.05,
                                          }}
                                          className="api-btns buy-btn"
                                        >
                                          Buy
                                        </motion.button>
                                      </a>
                                      {
                                        // ProductNameFromOtherComponent === "Foundation" ?
                                        //     <a
                                        //         target={"_blank"}>
                                        //         <motion.button
                                        //             variants={scaleAutomatically}
                                        //             onClick={() => (getVirtualDataForFoundation(
                                        //                 each_item.HEXColor,
                                        //                 each_item.Foundation,
                                        //                 each_item.Company
                                        //             ))}
                                        //             whileHover={{
                                        //                 scale: 1.05
                                        //             }}
                                        //             className='api-btns virtual-btn'
                                        //         >Virtual Try On</motion.button>
                                        //     </a> :
                                        //     <a href={each_item.InstagramFilter}
                                        //         target={"_blank"}>
                                        //         <motion.button
                                        //             variants={scaleAutomatically}
                                        //             whileHover={{
                                        //                 scale: 1.05
                                        //             }}
                                        //             className='api-btns virtual-btn'
                                        //         >Virtual Try On</motion.button>
                                        //     </a>
                                      }

                                      <a
                                        href={each_item.VideoTutorial}
                                        target={"_blank"}
                                      >
                                        <motion.button
                                          variants={scaleAutomatically}
                                          whileHover={{
                                            scale: 1.05,
                                          }}
                                          className="api-btns video-btn"
                                        >
                                          Video Tutorial
                                        </motion.button>
                                      </a>
                                    </div>
                                  </div>
                                ))
                              )}
                          </div>
                        </div>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ backgroundColor: "#EEE1D4" }}>
              <Modal.Header
                closeButton
                style={{ border: "none", float: "left" }}
              ></Modal.Header>
              <div className="upload-image-text-div">
                Upload Image for your{" "}
                <span style={{ color: "#A40036" }}>
                  {ProductNameFromOtherComponent}{" "}
                </span>{" "}
                <br /> Virtual Try-on.
              </div>

              <div>
                <div
                  className="file-uploader-div"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div className="File-Uploader-main-Div">
                    <FileUploader
                      handleChange={handleChange}
                      name="file"
                      types={fileTypes}
                    />
                    <button
                      onClick={handleChange}
                      style={{ border: "none", backgroundColor: "#EEE1D4" }}
                    >
                      <PiUploadSimpleThin className="Upload-icon" />
                    </button>
                  </div>
                </div>
              </div>

              <div className="upload-instructions-div">
                {ProductNameFromOtherComponent === "Nude Shoes" ? (
                  <p>
                    <p style={{ fontWeight: "bold" }}>FOR THE BEST RESULTS:</p>
                    1. Clear picture of feet in natural light. <br />
                    2. No items in the background.
                  </p>
                ) : (
                  <p>
                    <p style={{ fontWeight: "bold", marginBottom: "2px" }}>
                      FOR THE BEST RESULTS:
                    </p>
                    1. Select a clear picture of yourself in natural light.{" "}
                    <br />
                    2. Pull hair back. <br />
                    3. Make sure your face fills 80% of the image. <br />
                    4. No makeup or glasses.
                  </p>
                )}
              </div>
            </div>
          )}

          {isVirtualLoading ? (
            <div className="isloadingDiv">
              <div>
                <img
                  style={{ width: "1.5rem", marginTop: "1rem" }}
                  src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif"
                />
              </div>

              <p
                style={{
                  textAlign: "center",
                  fontSize: "1.2rem",
                  lineHeight: 1.2,
                  color: "white",
                }}
              >
                ...Applying {CompanyNameState} {FoundationCodeState} to your
                image
              </p>
            </div>
          ) : null}
        </Modal.Body>
      </Modal>
      <FoundationVirtual
        ShowFoundationVirtualTryon={ShowFoundationVirtualTryon}
        handleHideFoundationVirtualTryonModal={
          handleHideFoundationVirtualTryonModal
        }
        FoundationVirtualData={FoundationVirtualData}
        data={DataToVirtualTryOn}
        uploadedImage={uploadedImage}
        FoundationCodeState={FoundationCodeState}
        CompanyNameState={CompanyNameState}
        handleGoBack={handleGoBack}
      />
    </div>
  );
}
