import React from "react";
import NavigationBar from "../components/General/Navbar/NavigationBar";
import Accordion from "react-bootstrap/Accordion";
import Footer from "../components/General/Footer/Footer";

export default function FAQs() {
  return (
    <>
      <NavigationBar />

      <section className="faq section pt-0" style={{ marginTop: "10rem" }}>
        <div className="container terms-header-div">
          <h1>FREQUENTLY ASKED QUESTIONS</h1>
          <p>
            Find answers to common questions about yShade, our services, and
            more. <br /> If you can't find what you're looking for, please don't
            hesitate to contact us!
          </p>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-7 m-auto accordion-main">
              <div className="box-shadow">
                <Accordion defaultActiveKey="" flush>
                  <Accordion.Item eventKey="0" className="faq-item">
                    <div className="faq-item-title">
                      <h2>Getting Started</h2>
                    </div>

                    <Accordion.Header>
                      What is yShade and what do they do?
                    </Accordion.Header>
                    <Accordion.Body className="accordion-body">
                      yShade is an AI technology company that provides
                      innovative computer vision solutions for ALL skin shades.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="1" className="faq-item">
                    <Accordion.Header>
                      How do I get started with yShade?
                    </Accordion.Header>
                    <Accordion.Body className="accordion-body">
                      Simply send us a message on our website using the contact
                      form, and our team will be in touch to discuss your needs
                      and provide a customized solution.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2" className="faq-item">
                    <Accordion.Header>
                      What kind of support does yShade offer?
                    </Accordion.Header>
                    <Accordion.Body>
                      We offer comprehensive support, including documentation,
                      tutorials, and dedicated customer success managers, when
                      needed.
                    </Accordion.Body>
                  </Accordion.Item>
                  <span></span>
                  <span></span>

                  <Accordion.Item eventKey="3" className="faq-item">
                    <br />
                    <div className="faq-item-title">
                      <h2>Account</h2>
                    </div>

                    <Accordion.Header>
                      How do I create a business account with yShade?
                    </Accordion.Header>
                    <Accordion.Body className="accordion-body">
                      Send us a message using the form on our contact page and
                      we will follow up with you to get you set up.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4" className="faq-item">
                    <Accordion.Header>
                      Can I cancel my account at any time?
                    </Accordion.Header>
                    <Accordion.Body className="accordion-body">
                      Yes, you can cancel your account at any time by contacting
                      our customer success team.
                    </Accordion.Body>
                  </Accordion.Item>
                  <span></span>
                  <span></span>

                  <Accordion.Item eventKey="5" className="faq-item">
                    <br />
                    <div className="faq-item-title">
                      <h2> Pricing & License</h2>
                    </div>

                    <Accordion.Header>
                      How much does yShade cost?
                    </Accordion.Header>
                    <Accordion.Body>
                      Our pricing varies depending on your specific needs.
                      Contact our team for a customized quote.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6" className="faq-item">
                    <Accordion.Header>
                      What is included in my license?
                    </Accordion.Header>
                    <Accordion.Body>
                      Your license includes access to our custom AI technology,
                      and dedicated support.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7" className="faq-item">
                    <Accordion.Header>
                      Can I upgrade or downgrade my license?
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you can upgrade or downgrade your license at any time
                      by contacting our customer success team.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
