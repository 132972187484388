import React, { useState, useEffect } from "react";
import "./Nav.css";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import shadeWhite from "../../../assets/Home/yshade.png";
import shadeDark from "../../../assets/Home/yshadedark.png";
import NavDropdown from "react-bootstrap/NavDropdown";

import Form from "react-bootstrap/Form";

export default function NavigationBar() {
  const [toggled, setToggled] = useState(false);
  const [bgColor, setBgColor] = useState("transparent");
  const [scrolledToPoint, setScrolledToPoint] = useState(false);
  const [ShowProductsDropDown, setShowProductsDropDown] = useState(false);
  const [ShowCompanyDropDown, setShowShowCompanyDropDown] = useState(false);

  const onToggle = () => {
    setToggled(!toggled);
  };

  return (
    <div className="navbar-main">
      <Navbar
        fixed="top"
        expand="lg"
        className={"navbarcomponent"}
        onToggle={onToggle}
      >
        <Link to="/" style={{ textDecoration: "none" }}>
          <Navbar.Brand className="brand-name">
            <img src={shadeDark} className="img-fluid nav-logo-img" />
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle
          className="mobile-toggle-nav-btn"
          aria-controls="basic-navbar-nav"
        >
          {toggled ? <AiOutlineClose size={30} /> : null}
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {/* <div className='input-search-div'>
                            <Form.Control
                                type="text"
                                placeholder="Search"
                                className={'custom-nav-input-search-scrolled'}
                            />
                        </div> */}

            <NavDropdown
              title="Products"
              id={"basic-nav-dropdown-scrolled"}
              onMouseEnter={() => setShowProductsDropDown(true)}
              onMouseLeave={() => setShowProductsDropDown(false)}
              show={ShowProductsDropDown}
              onClick={() => setShowProductsDropDown(!ShowProductsDropDown)}
            >
              <a
                href="https://docs.robomua.com/"
                target="_blank"
                style={{
                  color: "black",
                  textDecoration: "none",
                  marginLeft: "0.9rem",
                }}
              >
                APIs
              </a>{" "}
              <br />
              <NavDropdown.Item>
                <Link
                  to="/robopage"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  roboMUA
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link
                  to="/yshade100"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  yShade100
                </Link>
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              title="Company"
              id={"basic-nav-dropdown-scrolled"}
              onMouseEnter={() => setShowShowCompanyDropDown(true)}
              onMouseLeave={() => setShowShowCompanyDropDown(false)}
              show={ShowCompanyDropDown}
              onClick={() => setShowShowCompanyDropDown(!ShowCompanyDropDown)}
            >
              <NavDropdown.Item>
                <Link
                  to="/about"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  About
                </Link>{" "}
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link
                  to="/team"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  Team
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                {/* <Link
                  to="/careers"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  Careers
                </Link>{" "} */}
              </NavDropdown.Item>
            </NavDropdown>

            <Link to="/demo" className={"nav-links-scrolled"}>
              Demo
            </Link>

            <Link
              to="/contactUs"
              className="nav-links-scrolled contactusnavlinkscrolled"
            >
              Contact Us
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
