import React, { useState, useEffect, useRef } from "react";
import "./Slider.css";
import { SliderData } from "./SliderData";

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const carouselRef = useRef(null);

  useEffect(() => {
    const autoSlide = () => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % SliderData.length);
    };

    const intervalId = setInterval(autoSlide, 7000);
    return () => clearInterval(intervalId);
  }, [activeIndex]);

  return (
    <div>
      <div className="carousel">
        <div className="carousel__list" ref={carouselRef}>
          {SliderData.map((item, index) => (
            <div
              onClick={() => setActiveIndex(index)}
              // onMouseEnter={() => setActiveIndex(index)}
              key={index}
              className={`carousel__item ${
                index === activeIndex ? "data-active" : ""
              }`}
              style={{ marginRight: index === 2 ? "unset" : "" }}
            >
              <div className="carousel__box">
                <div className="carousel__image">
                  <img src={item.img} />
                </div>
                {index === activeIndex ? (
                  <>
                    <div className="carousel__contents">
                      <div>
                        <h1 style={{ marginLeft: "1rem" }}>
                          {item.topCaption}
                        </h1>
                        <h3
                          className="carousel-intro-text"
                          dangerouslySetInnerHTML={{ __html: item.text }}
                        ></h3>
                      </div>

                      {activeIndex === 1 || activeIndex == 2 ? (
                        <div className="button-group">
                          <a href={item.link} target="_blank">
                            <button className="alyshashadebuttons">
                              {item.button1Texts}
                            </button>
                          </a>
                        </div>
                      ) : (
                        <div className="button-group">
                          <a href={item.link} target="_blank">
                            <button className="button1">
                              {item.button1Texts}
                            </button>
                          </a>
                          <a href={item.link2} target="_blank">
                            <button className="button2">
                              {item.button2Texts}
                            </button>
                          </a>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div></div>
                )}

                <div
                  className={`${
                    index === activeIndex
                      ? "carousel-main-text-div-active"
                      : "carousel-main-text-div"
                  }`}
                >
                  <div>{/* empty div is for flex box. don't delete */}</div>
                  {index !== activeIndex && (
                    <div style={{ marginBottom: index === 2 ? "0.7rem" : "" }}>
                      {item.caption}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
