import React, { useState } from "react";
// import "../CSS/nudewear.css";
// import "../CSS/QeblawiMain.css";

// import SignUPModal from "../Components/SignUpModal";

// import { getAuth, onAuthStateChanged } from "firebase/auth";
import DemoProductsOpened from "./DemoProductsOpened";
import face from "../../assets/Home/demoface.webp";
import lips from "../../assets/Home/demolips.webp";
import scents from "../../assets/Home/demoscents.webp";
import shapewear from "../../assets/Home/demoshape.webp";
import eyes from "../../assets/Home/demoeye.webp";
import hair from "../../assets/Home/demohair.webp";
import cheeks from "../../assets/Home/democheeks.webp";
import brows from "../../assets/Home/demobrows.webp";
import shoes from "../../assets/Home/demoshoe.webp";

import Swal from "sweetalert2";

const getAppAlert = (name, imgurl) => {
  Swal.fire({
    imageUrl: imgurl,
    text: `Take our ${name} Quiz on our Mobile App & Get your Personalized AI Recommendation`,
    showCloseButton: true,
    confirmButtonColor: "#9B6644",
    cancelButtonColor: "#827B75",
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText:
      '<a href="https://robomua.com/consumer" class="getTheApp"}>Get The App!</a> ',
    cancelButtonText: '<i class="fa fa-thumbs-down"></i> Cancel',
    cancelButtonAriaLabel: "Thumbs down",
    background: "#EEE1D4",
  });
};

const ComingSoonAlert = () => {
  Swal.fire({
    text: `Coming Soon`,
    showCloseButton: true,
    confirmButtonColor: "#9B6644",
    cancelButtonColor: "#827B75",
    showConfirmButton: false,
    showCancelButton: true,
    focusConfirm: false,
    cancelButtonText: '<i class="fa fa-thumbs-down"></i> Ok',
    background: "#EEE1D4",
  });
};

const DemoprodCard = ({
  product,
  checkifUserIsAuthenticatedToShowFoundationModalFunction,
}) => {
  const checkandgetFunction = () => {
    if (
      // product.id === 3 || product.id === 5
      product.id === 0 ||
      product.id === 0
    ) {
      getAppAlert(product.name, product.imageUrl);
      // handleDemoProductsOpenedModal(false);
    } else if (
      product.id === 8 ||
      // product.id === 1 ||
      // product.id === 2 ||
      // product.id === 4 ||
      product.id === 5 ||
      product.id === 8 ||
      // product.id === 7 ||
      // product.id === 9 ||
      product.id === 3
    ) {
      ComingSoonAlert(product.name, product.imageUrl);
      // handleDemoProductsOpenedModal(false);
    } else {
      checkifUserIsAuthenticatedToShowFoundationModalFunction(
        product.apiendpoint,
        product.imageUrl,
        product.whichopened,
        product.productToMapThrough
      );
    }
  };
  return (
    <button
      className="demoscard"
      onClick={checkandgetFunction}
      style={{
        border: "none",
        margin: 0,
        padding: 0,
        position: "relative",
      }}
    >
      <img
        src={product.imageUrl}
        alt={product.name}
        className="demosimg"
        style={{
          filter:
            // product.id === 1 ||
            // product.id === 2 ||
            // product.id === 4 ||
            product.id === 5 ||
            product.id === 8 ||
            // product.id === 7 ||
            // product.id === 9 ||
            product.id === 3
              ? "grayscale(100%)"
              : "none",
        }}
      />
      <div className="demo-products-names">{product.name}</div>
    </button>
  );
};

const products = [
  {
    id: 1,
    name: "Face",
    imageUrl: face,
    description: "Face",
    apiendpoint: "https://robomua-fashion-api.herokuapp.com/mynudeshade_gt",
    whichopened: "Face",
  },

  {
    id: 2,
    name: "Lips",
    imageUrl: lips,
    description: "Lips",
    apiendpoint: "https://robomua-fashion-api.herokuapp.com/mynudeshade_gt",
    whichopened: "Lips",
  },

  {
    id: 3,
    name: "Scents",
    imageUrl: scents,
    description: "Scents",
    apiendpoint: "https://robomua-fashion-api.herokuapp.com/mynudeshade_gt",
    whichopened: "Scents",
  },
  {
    id: 4,
    name: "Shapewear",
    imageUrl: shapewear,
    description: "Shapewear",
    apiendpoint: "https://robomua-fashion-api.herokuapp.com/mynudeshade_gt",
    whichopened: "Shapewear",
  },

  {
    id: 5,
    name: "Eyes",
    imageUrl: eyes,
    description: "Eyes",
    apiendpoint: "https://robomua-fashion-api.herokuapp.com/mynudeshade_gt",
    whichopened: "Eyes",
  },

  {
    id: 6,
    name: "Hair",
    imageUrl: hair,
    description: "Hair",
    apiendpoint: "https://robomua-fashion-api.herokuapp.com/mynudeshade_gt",
    whichopened: "Hair",
  },

  {
    id: 7,
    name: "Cheeks",
    imageUrl: cheeks,
    description: "Cheeks",
    apiendpoint: "https://robomua-fashion-api.herokuapp.com/mynudeshade_gt",
    whichopened: "Cheeks",
  },

  {
    id: 8,
    name: "Eyebrows",
    imageUrl: brows,
    description: "Eyebrows",
    apiendpoint: "https://robomua-fashion-api.herokuapp.com/mynudeshade_gt",
    whichopened: "Eyebrows",
  },

  {
    id: 9,
    name: "Shoes",
    imageUrl: shoes,
    description: "Shoes",
    apiendpoint: "https://robomua-fashion-api.herokuapp.com/mynudeshade_gt",
    whichopened: "Shoes",
    productToMapThrough: "LipsProducts",
  },
];

export default function DemoProductsModal({}) {
  const [DemoProductsOpenedModal, showDemoProductsOpenedModal] =
    useState(false);
  // const [showSignUPModal, setShowSignUPModal] = useState(false);
  const [apiendpointfromnude, setapiendpointfromnude] = useState("");
  const [nudefindImage, setnudefindImage] = useState("");
  const [DemoProductChosen, setDemoProductChosen] = useState("");

  const handleDemoProductsOpenedModal = () => {
    showDemoProductsOpenedModal(true);
  };

  const handleHideDemoProductsOpenendModal = () => {
    showDemoProductsOpenedModal(false);
  };

  const handleHideFaceAndLipsModal = () => showDemoProductsOpenedModal(false);

  // const handleCloseSignUPModal = (toggler) => {
  //   setShowSignUPModal(toggler);
  // };
  // const handleShowSignUPModal = (toggler) => setShowSignUPModal(toggler);

  const checkifUserIsAuthenticatedToShowFoundationModalFunction = (
    apiendpoint,
    nudeimage,
    nudename
  ) => {
    // const auth = getAuth();
    // onAuthStateChanged(auth, (user) => {
    //   if (user && apiendpoint !== undefined && nudefindImage !== undefined) {

    //   } else {
    //     handleShowSignUPModal(true);
    //   }
    // });

    handleDemoProductsOpenedModal();
    setapiendpointfromnude(apiendpoint);
    setnudefindImage(nudeimage);
    setDemoProductChosen(nudename);
  };

  const [file, setFile] = useState(null);

  const handleChange = (file) => {
    setFile(file);
  };

  return (
    <div className="demo-products">
      <div className="demo-products-container">
        {products.map((product) => (
          <DemoprodCard
            key={product.id}
            product={product}
            checkifUserIsAuthenticatedToShowFoundationModalFunction={
              checkifUserIsAuthenticatedToShowFoundationModalFunction
            }
          />
        ))}
      </div>

      <div style={{ zIndex: 99999, position: "absolute" }}>
        <div style={{ zIndex: 99999, position: "absolute" }}>
          {/* <SignUPModal
            show={showSignUPModal}
            handleHideSignUpModal={handleCloseSignUPModal}
            handleShowSignUPModal={handleShowSignUPModal}
          /> */}
        </div>
        <div style={{ zIndex: 99999, position: "absolute" }}>
          <DemoProductsOpened
            DemoProductsOpenedModal={DemoProductsOpenedModal}
            handleHideFaceAndLipsModal={handleHideFaceAndLipsModal}
            DemoProductChosen={DemoProductChosen}
            handleHideDemoProductsOpenendModal={
              handleHideDemoProductsOpenendModal
            }
          />
        </div>
      </div>
    </div>
  );
}
