import React from 'react'
import NavigationBar from '../components/General/Navbar/NavigationBar'
import CareerHero from '../components/CAREERS/CareerHero'
import CareerLife from '../components/CAREERS/CareerLife'
import CareerJobs from '../components/CAREERS/CareerJobs'
import Footer from '../components/General/Footer/Footer'
export default function Careers() {
    return (
        <div>
            <NavigationBar />
            <CareerHero/>
            <CareerLife/>
            <CareerJobs/>
            <Footer/>
         
            
        </div>
    )
}
