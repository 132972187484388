import React from "react";
import chromeImg from "../../assets/Home/carou1.webp";
import "./products.css";
import { motion } from "framer-motion";

export default function Webpage() {
  const titleVariant = {
    slideInFromRight: {
      scale: 1.1,
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
  };

  const CardsVariant = {
    slideIn: {
      y: 0,
      transition: {
        duration: 1,
      },
    },

    slideInFromRight: {
      x: 0,
      transition: {
        duration: 1,
      },
    },
  };
  return (
    <div>
      <div className="container px-5">
        <br />
        <div className="row gx-5 align-items-center robomua-hero-main justify-content-center">
          <div className="img-video-div col-lg-6">
            <div className="col-lg-12">
              <img src={chromeImg} className="img-fluid chrome-browser-img" />
              <div className="hero-main-text">
                <h1 className="hero-main-text chrome-heading text-center">
                  coming soon!
                  <br />
                </h1>
              </div>
              <div>
                <p className="get-ready-text">
                  Get ready for something amazing! We're hard at work creating a
                  brand-new experience just for you. Stay tuned for exciting
                  updates.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}
