import React, { useEffect, useState } from "react";
import HomePage from "./Screens/HomePage";
import Products from "./Screens/Products";
import AboutUs from "./Screens/AboutUs";
import Careers from "./Screens/Careers";
import Company from "./Screens/Company";
import Demo from "./Screens/Demo";
import ContactUs from "./Screens/ContactUs";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { useLockBodyScroll } from "@uidotdev/usehooks";
import { ParallaxProvider } from "react-scroll-parallax";
import Robomua from "./components/Products/Robomua";
import YShadePage from "./components/Products/YShade/YShadePage";
import Alysha from "./components/Home/Alysha";
import Team from "./Screens/Team";
import Terms from "./Screens/Terms";
import PrivacyPolicy from "./Screens/PrivacyPolicy";
import FAQs from "./Screens/FAQs";

export default function App() {
  return (
    <div className="mother-component">
      <ParallaxProvider>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/products" element={<Products />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/company" element={<Company />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/robopage" element={<Robomua />} />
          <Route path="/yshade100" element={<YShadePage />} />
          <Route path="/aiysha" element={<Alysha />} />
          <Route path="/team" element={<Team />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/faqs" element={<FAQs />} />
        </Routes>
      </ParallaxProvider>
    </div>
  );
}
