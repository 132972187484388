import React, { useState, useEffect, useRef } from "react";

import Modal from "react-bootstrap/Modal";

import DemoTryOnModal from "./DemoTryOnModal";
import { FileUploader } from "react-drag-drop-files";
import { PiUploadSimpleThin } from "react-icons/pi";

const fileTypes = ["JPG", "PNG", "JPEG"];

export default function UploadDemoImageModal({
  showLipsHairDemoModal,
  hideLipsDemoModal,
  DemoProductChosen,
  ProductApi,
  ProductNameFromOtherComponent,
  DemoProductName,
}) {
  const ref = useRef();

  const [uploadedImage, setUploadedImage] = useState("");
  const [displayedUploadedImage, setdisplayedUploadedImage] = useState("");
  const [SelectedBrandId, setSelectedBrandId] = useState("");
  const [isVirtualLoading, setisVirtualLoading] = useState(false);
  const [lipsResultsModal, setLipsResultsModal] = useState(false);
  const [showDemoLipsVirtualModal, setshowDemoLipsVirtualModal] =
    useState(false);
  const [ResultsFromLips, setResultsFromLips] = useState("");
  const [SelectedCompanyName, setSelectedCompanyName] = useState(
    "Anastasia Beverly Hills"
  );
  const [TryonProducts, setTryonProducts] = useState("");

  const handleshowDemoLipsVirtualModal = () =>
    setshowDemoLipsVirtualModal(true);
  const handleHideDemoLipsVirtualModal = () =>
    setshowDemoLipsVirtualModal(false);
  const [file, setFile] = useState(null);

  const selectedEventFunction = (id, name) => {
    setSelectedBrandId(id);
    setSelectedCompanyName(name);
  };

  const hideUploadImageModal = () => {
    hideLipsDemoModal();
    setUploadedImage("");
    setdisplayedUploadedImage("");
  };

  const getVirtualDataForLips = () => {
    if (uploadedImage === "") {
      alert("No Image Was Uploaded.");
    } else {
      {
        handleshowDemoLipsVirtualModal();
        setUploadedImage(uploadedImage);
        setdisplayedUploadedImage(displayedUploadedImage);
      }
    }
  };

  const handleChange = (file) => {
    setUploadedImage(file);
    setdisplayedUploadedImage(URL.createObjectURL(file));
    handleshowDemoLipsVirtualModal();
  };

  const tyronFunction = () => {
    if (DemoProductName === "Lipstick") {
      return setTryonProducts("Lipstick");
    } else if (DemoProductName === "Blush") {
      return setTryonProducts("Blush");
    } else if (DemoProductName === "Lip Liner") {
      return setTryonProducts("Lip Liner");
    } else if (DemoProductName === "Lipstick & Lip Liner Combo") {
      return setTryonProducts("Lipstick & Lip Liner Combo");
    } else if (DemoProductName === "Hair Color Try-on") {
      return setTryonProducts("Hair Color");
    }
  };

  useEffect(() => {
    tyronFunction();
    return () => {};
  }, [DemoProductChosen, DemoProductName]);

  return (
    <div>
      <Modal
        show={showLipsHairDemoModal}
        onHide={hideLipsDemoModal}
        size="lg"
        backdrop="static"
        keyboard={"false"}
        style={{
          backdropFilter: "blur(10px)",
          WebkitBackdropFilter: "blur(10px)",
        }}
      >
        <Modal.Body
          style={{
            backgroundColor: "#EEE1D4",
            minHeight: "75vh",
          }}
        >
          <div style={{}}>
            <Modal.Header
              closeButton
              style={{
                border: "none",
                float: "left",
                color: "#9B6644",
                position: "absolute",
              }}
            ></Modal.Header>

            <div className="upload-image-text-div">
              Upload Image for your{" "}
              <span style={{ color: "#A40036" }}>{DemoProductName} </span>{" "}
              <br /> Virtual Try-on.
            </div>

            <div>
              <div
                className="file-uploader-div"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div className="File-Uploader-main-Div">
                  <FileUploader
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                  />
                  <button
                    onClick={handleChange}
                    style={{ border: "none", backgroundColor: "#EEE1D4" }}
                  >
                    <PiUploadSimpleThin className="Upload-icon" />
                  </button>
                </div>
              </div>
            </div>

            <div className="upload-instructions-div">
              {ProductNameFromOtherComponent === "Nude Shoes" ? (
                <p>
                  <p style={{ fontWeight: "bold" }}>FOR THE BEST RESULTS:</p>
                  1. Clear picture of feet in natural light. <br />
                  2. No items in the background.
                </p>
              ) : (
                <p>
                  <p style={{ fontWeight: "bold", marginBottom: "2px" }}>
                    FOR THE BEST RESULTS:
                  </p>
                  1. Select a clear picture of yourself in natural light. <br />
                  2. Pull hair back. <br />
                  3. Make sure your face fills 80% of the image. <br />
                  4. No makeup or glasses.
                </p>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <DemoTryOnModal
        showDemoLipsVirtualModal={showDemoLipsVirtualModal}
        handleHideDemoLipsVirtualModal={handleHideDemoLipsVirtualModal}
        ResultsFromLips={ResultsFromLips}
        uploadedImage={uploadedImage}
        displayedUploadedImage={displayedUploadedImage}
        ProductApi={ProductApi}
        DemoProductChosen={DemoProductChosen}
        ProductNameFromOtherComponent={ProductNameFromOtherComponent}
        DemoProductName={DemoProductName}
      />
    </div>
  );
}
