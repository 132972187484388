import React from "react";
import Slider from "react-slick";
import sliderimg from "../../assets/Home/Untitled design-6.png";
import "../../App.css";

export default function AboutCreated() {
  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
  };

  return (
    <div>
      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title mb-0">
                <h2>Why We Created yShade</h2>
                <p>
                  We created yShade to address the significant gap in AI
                  solutions for diverse skin shades and types, with a mission to
                  build custom algorithms that cater to all skin tones and
                  empower individuals with personalized experiences. We believe
                  that everyone deserves to feel seen and represented, and we're
                  committed to driving inclusivity and efficiency across
                  industries. By revolutionizing skin recognition and analysis,
                  we aim to create a future where AI solutions adapt to every
                  unique skin shade.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="section create-stories pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="block">
                <img className="img-fluid" src={sliderimg} alt="Story-Image" />
                <h3>Our Story</h3>
                <p>
                  yShade was founded on the belief that AI solutions should
                  cater to all skin shades and types, driven by a passion to
                  revolutionize industries and empower individuals with
                  personalized experiences. From our humble beginnings to our
                  current cutting-edge solutions, our story is one of
                  innovation, inclusivity, and a relentless pursuit of
                  excellence.
                </p>
              </div>
            </div>
            <div className="col-lg-6 mt-5 mt-lg-0">
              <div className="block">
                <img className="img-fluid" src={sliderimg} alt="Story-Image" />
                <h3>What We Do</h3>
                <p>
                  At yShade, we develop and implement custom AI algorithms that
                  excel in skin recognition, analysis, and matching, providing
                  innovative solutions for industries to cater to diverse skin
                  shades and types. Our expertise in AI and computer vision
                  enables us to design tailored solutions for our partners,
                  driving inclusivity and efficiency across multiple sectors.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Slider {...settings}>
        <div className="col-10 m-auto text-center item mb-4">
          <h1>Diversity doesn't look like anyone. It looks like everyone.</h1>
          <cite className="ml-0">- Karen Draper</cite>
        </div>

        <div className="col-10 m-auto text-center">
          <h1>
            Diversity is being asked to the party; inclusion is being asked to
            dance.
          </h1>
          <cite className="ml-0">- Verna Myers</cite>
        </div>
      </Slider>
    </div>
  );
}
