import React, { useState } from "react";
import { motion } from "framer-motion";
import { Spinner } from "react-activity";
import Slider from "react-slick";

export default function DontBelieve() {
  var settings = {
    dots: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  return (
    <section className=" testimonial" id="testimonial">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="testimonial-slider owl-carousel owl-theme">
              <Slider {...settings}>
                <div className="item">
                  <div className="block shadow">
                    <p>
                      I am really happy to have teamed up with Emmanuel and
                      roboMUA with my company Everyday Girl Cosmetics, Before
                      working with them it was very difficult for my customers
                      to purchase my concealer items because they did not know
                      if it would match their skin tone or not. roboMUA makes it
                      very easy for someone to find their perfect shade of
                      products in two simple steps. I highly recommend using
                      this technology for any beauty/cosmetic brand. Your
                      customers would thank you and it will make it so much
                      easier on you as a business owner when adding new products
                      that require shade matching.
                    </p>
                    <div className="image">
                      {/* <img src="/images/home/dp.png" alt="user-testimonial"/> */}
                    </div>
                    <cite>CEO & Founder - Everyday Girl Cosmetics</cite>
                  </div>
                </div>
                <div className="item">
                  <div className="block shadow">
                    <p>
                      Not only did you find the perfect skin tint for me you
                      also recommended me one of the foundations that I used the
                      most. That's so cool and I really think the AI you're
                      working with is really... Well it's innovative to me!
                      Really cool stuff and you could be changing the way we
                      consume, and buy, and research makeup completely That's so
                      very exciting! Congratulations.
                    </p>
                    <div className="image">
                      {/* <img src="/images/home/dp.png" alt="user-testimonial"/> */}
                    </div>
                    <cite>User - Yaa O.</cite>
                  </div>
                </div>
                <div className="item">
                  <div className="block shadow">
                    <p>
                      roboMUA helped me pick my skin tint. I uploaded my picture
                      and it recommended NARS for me. I was so excited because
                      the way the product blended on my skin was crazy, you
                      couldn't even tell I was wearing makeup.
                    </p>
                    <div className="image">
                      {/* <img src="/images/home/dp.png" alt="user-testimonial"/> */}
                    </div>
                    <cite>User - Ashley</cite>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </section>
  );
}
