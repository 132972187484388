import React, { useState, useEffect } from "react";
import skinshadeImg from "../../../assets/Home/skin-shades.png";
import "./YShade.css";
import { motion, useAnimation } from "framer-motion";
import { Link } from "react-router-dom";

import NavigationBar from "../../General/Navbar/NavigationBar";
import vid from "../../../assets/Home/vid.mp4";
import Footer from "../../General/Footer/Footer";
import BannerImage from "../../../assets/Home/banneImg.jpeg";

export default function YShadePage() {
  const handleAnimationStart = () => {};

  const handleAnimationComplete = () => {};

  const titleVariant = {
    slideInFromRight: {
      opacity: 1,
      transition: {
        duration: 10,
      },
    },
  };

  return (
    <div className="">
      <NavigationBar />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <section className="full-page-video-Shade">
          <video
            autoPlay
            controls
            playsInline
            loop
            muted
            className="full-page-video-Shade__content img-fluid"
          >
            <source src={vid} type="video/mp4" />
          </video>
        </section>
      </div>

      <div className="yshade-text-image-div">
        <div>
          <motion.div
            variants={titleVariant}
            initial={{}}
            transition={{
              duration: 1,
            }}
            whileInView="slideInFromRight"
            onAnimationStart={handleAnimationStart}
            onAnimationComplete={handleAnimationComplete}
            className="skinshade-texts"
          >
            Our Industry-Defining Dataset: 10 Million Images, 100 Skin Shades.
            We go beyond the surface, accounting for variations in lighting and
            weather conditions to provide a robust foundation for powerful AI.
            We believe AI should celebrate the beauty of every skin tone.
          </motion.div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button className="contact-us-div-yshade">
              <Link to="/contactUs" className="yshade-contact-Link">
                Contact Us
              </Link>
            </button>
          </div>
        </div>
        <div>
          <img
            src={skinshadeImg}
            className="img-fluid skinshadesImage"
            alt=""
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={BannerImage} alt="" className="banner-image" />
      </div>

      <Footer />
    </div>
  );
}
