import React from "react";
import NavigationBar from "../components/General/Navbar/NavigationBar";
import Footer from "../components/General/Footer/Footer";
import AboutCreated from "../components/ABOUT/AboutCreated";
import AboutHero from "../components/ABOUT/AboutHero";
import AboutFeatured from "../components/ABOUT/AboutFeatured";

export default function AboutUs() {
  return (
    <div>
      <NavigationBar />
      <AboutHero />
      <AboutCreated />
      <AboutFeatured />
      <Footer />
    </div>
  );
}
