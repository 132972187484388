import React from "react";
import Slider from "react-slick";
import sliderimg1 from "../../assets/Home/mission.webp";
import sliderimg2 from "../../assets/Home/w.webp";
import "../../App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function AboutHero() {
  const settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
  };

  return (
    <div>
      <section className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-sm-8 m-auto about-div">
              <h1>About yShade.AI</h1>
              <p>
                yShade is an innovative AI solutions company with a visionary
                focus on catering to all skin shades and types.
              </p>
            </div>
          </div>

          <br />
          <br />

          <section className="section about p-0">
            <div className="container">
              <div className="row our-story-row">
                <div className="col-lg-6 align-self-center ">
                  <div className="content text-center text-lg-left our-story-text">
                    <h2>This is our story.</h2>
                    <p>
                      At yShade, we're driven by a passion to revolutionize the
                      way industries serve diverse skin shades and types, with a
                      vision to create custom AI algorithms that excel in skin
                      recognition, analysis, and matching. With a team of
                      experts in AI, computer vision, and software engineering,
                      we've developed proprietary algorithms built on unique,
                      inclusive datasets of over 100 different skin shades,
                      enabling us to design tailored solutions for our partners.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 mt-4 mt-lg-0">
                  <Slider {...settings}>
                    <div>
                      <img src={sliderimg2} alt="" className="w-100" />
                    </div>

                    <div>
                      <img src={sliderimg1} alt="" className="w-100" />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}
