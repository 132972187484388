import React, { useState, useEffect } from "react";

import Modal from "react-bootstrap/Modal";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import imgMain from "../assets/Home/Face.webp";
import AllDemoProductsModal from "../components/DEMO/AllDemoProductsModal";
import NavigationBar from "../components/General/Navbar/NavigationBar";
import Footer from "../components/General/Footer/Footer";
import "../components/DEMO/Demo.css";

export default function Demo({}) {
  const [showDemoModal, setshowDemoModal] = useState(false);

  const showModal = () => {
    setshowDemoModal(true);
  };

  const hideModal = () => {
    setshowDemoModal(false);
  };

  return (
    <>
      <div className="demo-main" onClick={showModal}>
        <NavigationBar />
        <div className="face-back-div">
          <div>
            <img src={imgMain} />
            <div className="click-any-where-div">
              <div>Click anywhere to begin</div>
            </div>
          </div>
        </div>

        <Modal
          show={showDemoModal}
          size="lg"
          backdrop="static"
          keyboard={"false"}
          style={{
            backdropFilter: "blur(2px)",
            WebkitBackdropFilter: "blur(2px)",
          }}
          className="demo-products-modal-main"
        >
          <Modal.Body className="demo-prdoucts-body-modal">
            <Link to="/" style={{ float: "right" }}>
              <AiOutlineClose color="black" size={30} />
            </Link>
            <AllDemoProductsModal />
          </Modal.Body>
        </Modal>
      </div>
      <Footer />
    </>
  );
}
