import React from 'react'
import NavigationBar from '../components/General/Navbar/NavigationBar'

export default function Products() {
    return (
        <div>
            <NavigationBar /><br /><br /><br /><br />
            <h1>PRODUCTS PAGE</h1>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Hic provident in ratione veniam non, veritatis, repellat quisquam delectus adipisci aliquid alias blanditiis et ab sint unde aperiam! Repellendus, exercitationem tempora?
        </div>
    )
}
