import React from "react";
import chromeImg from "../../assets/Home/chrome2.webp";
import "./products.css";
import { motion } from "framer-motion";

export default function Chrome() {
  const titleVariant = {
    slideInFromRight: {
      scale: 1.1,
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
  };

  const CardsVariant = {
    slideIn: {
      y: 0,
      transition: {
        duration: 1,
      },
    },

    slideInFromRight: {
      x: 0,
      transition: {
        duration: 1,
      },
    },
  };
  return (
    <div>
      <div className="container px-5">
        <div className="row gx-5 align-items-center robomua-hero-main">
          <div className="col-lg-6">
            <div className="hero-main-text">
              <h1 className="hero-main-text chrome-heading">
                Use roboMUA
                <br />
                on your chrome browser
                <br />
              </h1>
            </div>
            <div>
              <p className="chrome_text">
                Our chrome extension is an easy way to get personalized beauty
                product recommendations based on your unique skin shade. Simply
                download the extension from the Chrome Web Store and log in with
                your account. Once logged in, you can access the extension from
                your browser's toolbar..
              </p>
            </div>

            <div className="download-btns-div">
              <a
                href="https://chromewebstore.google.com/detail/robomua/cpdcgmengmfmelelldfjgocpiikfmggp?authuser=1?authuser%3D1&gclid=CjwKCAjwq-WgBhBMEiwAzKSH6HRczta4rpRMtDG-Oqm1jXeOkNtAIahrvnC8s7neelHFmQ0p7PQAABoCSVYQAvD_BwE"
                target="_blank"
              >
                <button
                  className="download-chrome-btn"
                  style={{ color: "white" }}
                >
                  Install
                </button>
              </a>
            </div>
          </div>
          <div className="img-video-div col-lg-6">
            <img src={chromeImg} className="img-fluid chrome-browser-img" />
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}
