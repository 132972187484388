import image1 from "../../assets/Home/HairStyles/1.jpeg";
import image2 from "../../assets/Home/HairStyles/2.jpg";
import image3 from "../../assets/Home/HairStyles/3.jpg";
import image4 from "../../assets/Home/HairStyles/4.jpg";
import image5 from "../../assets/Home/HairStyles/5.jpeg";
import image6 from "../../assets/Home/HairStyles/6.jpeg";
import image7 from "../../assets/Home/HairStyles/7.jpeg";
import image8 from "../../assets/Home/HairStyles/8.jpeg";
import image9 from "../../assets/Home/HairStyles/9.jpg";
import image10 from "../../assets/Home/HairStyles/10.jpeg";
import image11 from "../../assets/Home/HairStyles/11.jpg";
import image12 from "../../assets/Home/HairStyles/12.jpeg";
import image13 from "../../assets/Home/HairStyles/13.jpg";
import image14 from "../../assets/Home/HairStyles/14.jpeg";
import image15 from "../../assets/Home/HairStyles/15.jpg";
import image16 from "../../assets/Home/HairStyles/16.jpg";
import image17 from "../../assets/Home/HairStyles/17.jpeg";
import image18 from "../../assets/Home/HairStyles/18.jpeg";
import image19 from "../../assets/Home/HairStyles/19.jpeg";
import image20 from "../../assets/Home/HairStyles/20.jpeg";
import Blank from "../../assets/Home/HairStyles/blank.png";

export const ImagesUrl = [
  {
    id: 1,
    imgsource: Blank,
  },
  {
    id: 2,
    imgsource: image1,
  },
  {
    id: 3,
    imgsource: image2,
  },
  {
    id: 4,
    imgsource: image3,
  },
  {
    id: 5,
    imgsource: image4,
  },
  {
    id: 6,
    imgsource: image5,
  },
  {
    id: 7,
    imgsource: image6,
  },

  {
    id: 8,
    imgsource: image7,
  },

  {
    id: 9,
    imgsource: image8,
  },

  {
    id: 10,
    imgsource: image9,
  },

  {
    id: 11,
    imgsource: image10,
  },

  {
    id: 12,
    imgsource: image11,
  },

  {
    id: 13,
    imgsource: image12,
  },

  {
    id: 14,
    imgsource: image13,
  },

  {
    id: 15,
    imgsource: image14,
  },

  {
    id: 16,
    imgsource: image15,
  },

  {
    id: 17,
    imgsource: image16,
  },

  {
    id: 18,
    imgsource: image17,
  },

  {
    id: 19,
    imgsource: image18,
  },

  {
    id: 20,
    imgsource: image19,
  },

  {
    id: 20,
    imgsource: image20,
  },
];
