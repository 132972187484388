import React from 'react';
import Footer from '../components/General/Footer/Footer';
import NavigationBar from '../components/General/Navbar/NavigationBar';
import '../App.css'

const PrivacyPolicy = () => {
  return (
    <>
          <NavigationBar />
 <section className="privacy section pt-0">
              <div className="container terms-header-div">
                     <h1>PRIVACY POLICY</h1>
                  <p>
                      We're committed to protecting your personal information and being
                      transparent about how we collect, use, <br />
                      and share your data - read on to learn more about our privacy
                      practices and how we keep your trust.
                  </p>
		<div className="row">
			<div className="col-lg-3">
				<nav className="privacy-nav">
					<ul>
						<li><a className="nav-link scrollTo" href="#infoWeCollect" >Information We Collect</a></li>
						<li><a className="nav-link scrollTo" href="#disclaimer" >Disclaimer</a></li>
						<li><a className="nav-link scrollTo" href="#dataSecurity" >Data Security</a></li>
						<li><a className="nav-link scrollTo" href="#governigLaw" >Governing Law</a></li>
					</ul>
				</nav>
			</div>
			<div className="col-lg-9">
				<div className="block">
					<div id="infoWeCollect" className="policy-item">
						<div className="title">
							<h3>Information We Collect</h3>
						</div>
						<div className="policy-details">
							<p>We collect personal information from you when you use our services, such as your name, email address, and other contact details. We also collect data from your interactions with our website and services, including browsing history, search queries, and other usage data. This information helps us to provide you with a better experience and to improve our services.</p>
							<p>We may also collect sensitive information, such as your location or device data, with your consent. We will always ask for your explicit consent before collecting any sensitive information.</p>
						</div>
					</div>
					<div id="disclaimer" className="policy-item">
						<div className="title">
							<h3>Disclaimer</h3>
						</div>
						<div className="policy-details">
							<p>yShade is committed to protecting your privacy, but we cannot guarantee the security of our services. We use reasonable efforts to protect your personal information from unauthorized access, disclosure, modification, or destruction. However, we cannot guarantee that our services will be completely secure or that your personal information will never be disclosed in a manner inconsistent with this Privacy Policy.
              </p>
						</div>
					</div>
					<div id="dataSecurity" className="policy-item">
						<div className="title">
							<h3>Data Security</h3>
						</div>
						<div className="policy-details">
							<p>We take data security seriously and have implemented appropriate technical and organizational measures to protect your personal information. We use encryption, firewalls, and other security measures to protect your data from unauthorized access. We also limit access to your personal information to only those employees, contractors, and agents who need it to perform their jobs.</p>
						</div>
					</div>
					<div id="governigLaw" className="policy-item">
						<div className="title">
							<h3>Governing Law</h3>
						</div>
						<div className="policy-details">
							<p>This Privacy Policy will be governed by and construed in accordance with the laws of the state of Delaware. Any disputes arising out of or related to this Privacy Policy will be resolved through binding arbitration in accordance with the rules of the American Arbitration Association, as amended from time to time.</p>
							<p>By using our services, you consent to the exclusive jurisdiction of the state and federal courts located in Delaware for any disputes arising out of or related to this Privacy Policy.</p>
              <p>Please note that we may update this Privacy Policy from time to time, and any changes will be effective upon posting. We will notify you of any significant changes by posting a notice on our website or by sending an email to the email address we have on file for you.</p>
              <p>If you have any questions or concerns about this Privacy Policy, please contact us at hello@yshade.ai.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

    <Footer/>

    </>
  );
};

export default PrivacyPolicy;
