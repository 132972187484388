import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { AiOutlineClose, AiOutlineShoppingCart } from "react-icons/ai";
import axios from "axios";
import { brands, KylieCostmetics, RareBeauty } from "./BrandsData";
import Dropdown from "react-bootstrap/Dropdown";
import { ImagesUrl } from "./HairImagesData";
import { IoIosArrowRoundDown } from "react-icons/io";
import { MdOutlineBlock } from "react-icons/md";
import Blank from "../../assets/Home/HairStyles/blank.png";

export default function DemoLipsVirtualModal({
  showDemoLipsVirtualModal,
  handleHideDemoLipsVirtualModal,
  uploadedImage,
  displayedUploadedImage,
  ProductApi,
  DemoProductChosen,
  ProductNameFromOtherComponent,
  DemoProductName,
}) {
  const source = axios.CancelToken.source();

  const [virtualIsloading, setisVirtualLoading] = useState(false);
  const [newImageRequestMade, setNewImageRequestMade] = useState(false);
  const [ResultFromApi, setNewResultsFromApi] = useState("");
  const [ColorNameSelected, setSelectedColorName] = useState("");
  const [colorClickedId, setColorclickedId] = useState(0);
  const [SelectedBrandId, setSelectedBrandId] = useState("");
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [CompanyNameSelected, setCompanyNameSelected] = useState("");
  const [companyLink, setcompanyLink] = useState("");
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isDropLipLinerComboDownOpen, setisDropLipLinerComboDownOpen] =
    useState(false);
  const [brandSelected, setBrandSelected] = useState("Choose Brand");
  const [HairStyleidSelected, setHairStyleidSelected] = useState("");
  const [AddingProductText, setAddingProductText] = useState("");
  const [BrandClicked, setBrandClicked] = useState(false);
  const [BlankIsClicked, setBlankedIsClicked] = useState(false);

  const getVirtualColorForLipsAndHair = (
    colorSelected,
    colorNameSelected,
    id,
    companyName
  ) => {
    setBlankedIsClicked(false);
    setCompanyNameSelected(companyName);
    setColorclickedId(id);
    setisVirtualLoading(true);
    let form = new FormData();
    form.append("file", uploadedImage);
    form.append("color", colorSelected);
    setSelectedColorName(colorNameSelected);

    axios
      .post(ProductApi, form)
      .then((results) => {
        setNewImageRequestMade(false);
        setisVirtualLoading(false);
        setNewResultsFromApi(results.data);
      })
      .catch((error) => {
        setisVirtualLoading(false);
        alert(
          "Error!! Please use a clearer image or check your internet connectivity",
          error.message
        );
        console.log(error);
      });
  };

  const getVirtualDataHairStyle = (id) => {
    setBlankedIsClicked(false);
    setHairStyleidSelected(id);
    setisVirtualLoading(true);
    setBrandClicked(true);
    let form = new FormData();
    form.append("file", uploadedImage);
    form.append("hair", id);
    axios
      .post(
        "https://robomua-hairstyle-b3bfbd2af446.herokuapp.com/api/hair",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((results) => {
        setNewImageRequestMade(true);
        setisVirtualLoading(false);
        setNewResultsFromApi(results.data);
      })
      .catch((error) => {
        setisVirtualLoading(false);
        alert(
          "Error!! Please use a clearer image or check your internet connectivity",
          error.message
        );
        console.log(error);
      });
  };

  const hideModal = () => {
    source.cancel("Request canceled by user");
    handleHideDemoLipsVirtualModal();
    setNewResultsFromApi("");
    setcompanyLink("");
    setCompanyNameSelected("");
    setBrandSelected("Choose Brand");
    setColorclickedId("");
    setSelectedBrand(null);
    setisVirtualLoading(false);
    setBrandClicked(false);
  };

  const handleBrandClick = (brand) => {
    setSelectedBrand(brand);
    setSelectedBrandId(brand.id);
    setcompanyLink(brand.link);

    setBrandSelected(brand.Name);
    setIsDropDownOpen(false);
    setBrandClicked(true);
  };

  const DropdownMap = () => {
    if (DemoProductName === "Lip Liner") {
      return KylieCostmetics?.map((each_brand, index) => {
        return (
          <div key={index}>
            <Dropdown.Item
              className="dropdownItems"
              key={each_brand.id}
              onClick={() => handleBrandClick(each_brand)}
            >
              {each_brand.Name}
            </Dropdown.Item>
          </div>
        );
      });
    } else if (DemoProductName === "Blush") {
      return RareBeauty?.map((each_brand, index) => {
        return (
          <div key={index}>
            <Dropdown.Item
              className="dropdownItems"
              key={each_brand.id}
              onClick={() => handleBrandClick(each_brand)}
            >
              {each_brand.Name}
            </Dropdown.Item>
          </div>
        );
      });
    } else {
      return brands?.map((each_brand, index) => {
        return (
          <div key={index}>
            <Dropdown.Item
              className="dropdownItems"
              key={each_brand.id}
              onClick={() => handleBrandClick(each_brand)}
            >
              {each_brand.Name}
            </Dropdown.Item>
          </div>
        );
      });
    }
  };

  const addingProductTextFunction = () => {
    if (DemoProductChosen === "Lips") {
      setAddingProductText("Lips");
    } else if (DemoProductChosen === "Hair") {
      setAddingProductText("Hair");
    } else if (DemoProductChosen === "Cheeks") {
      setAddingProductText("Cheeks");
    }
  };

  useEffect(() => {
    addingProductTextFunction();
  }, [DemoProductChosen]);

  return (
    <div>
      <Modal
        show={showDemoLipsVirtualModal}
        onHide={hideModal}
        backdrop="static"
        keyboard={"false"}
        style={{ backgroundColor: "#43403D" }}
      >
        <Modal.Body className="modal-body-hairstyles">
          <div
            className={
              BrandClicked === false
                ? "modal-inner-div-blur"
                : "modal-inner-div"
            }
          >
            <div className="modal-inner-inner-div">
              <a
                style={{
                  position: "absolute",
                  padding: "1rem",
                  left: 0,
                  zIndex: 999,
                }}
              >
                <AiOutlineClose color="#9D6948" onClick={hideModal} size={30} />
              </a>

              {/* Shopping Cart Code */}
              {/* Shopping Cart Code */}
              {/* Shopping Cart Code */}
              {ProductNameFromOtherComponent === "Hair Style Try-on" ? (
                <div></div>
              ) : (
                <a
                  target="_blank"
                  onClick={() => {
                    companyLink === ""
                      ? alert("Please Choose A brand")
                      : window.open(companyLink, "_blank");
                  }}
                  style={{
                    position: "absolute",
                    right: 0,
                    zIndex: 999,
                    padding: "1rem",
                  }}
                >
                  <AiOutlineShoppingCart color="#9D6948" size={30} />
                </a>
              )}

              {/* Image Virtual */}
              {/* Image Virtual */}
              {/* Image Virtual */}
              {ResultFromApi?.message ===
                "Processed image variable not defined." ||
              ResultFromApi === "" ? (
                <img
                  src={displayedUploadedImage}
                  className="fentyvirtualImage img-fluid"
                  style={{
                    width: "100%",
                    filter: virtualIsloading && "blur(5px)",
                  }}
                />
              ) : (
                <img
                  src={
                    BlankIsClicked === true
                      ? displayedUploadedImage
                      : `data:image/jpeg;base64,${ResultFromApi.b64}`
                  }
                  alt=""
                  className="fentyvirtualImage img-fluid"
                  style={{
                    filter: virtualIsloading && "blur(5px)",
                    minWidth: "100%",
                    minHeight: "60vh",
                    aspectRatio: "1/1",
                    objectFit: "cover",
                  }}
                />
              )}

              {/* Hair ImageVirtualLoader */}
              {/* Hair ImageVirtualLoader */}
              {/* Hair ImageVirtualLoader */}

              {ProductNameFromOtherComponent === "Hair Style Try-on"
                ? virtualIsloading && (
                    <span className="virtual-loader">
                      <br />
                      <br />
                      <img
                        style={{ width: "1.5rem", marginTop: "1rem" }}
                        src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif"
                      />{" "}
                      <br />
                      Changing Hair Style
                    </span>
                  )
                : // Color VirtualLoader
                  // Color VirtualLoader
                  // Color VirtualLoader

                  virtualIsloading && (
                    <span className="virtual-loader">
                      <br />
                      <br />
                      <img
                        style={{ width: "1.5rem", marginTop: "1rem" }}
                        src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif"
                      />{" "}
                      <br />
                      Adding {CompanyNameSelected}'s {ColorNameSelected} color
                      to your {AddingProductText}
                    </span>
                  )}
            </div>

            {/* HairStyleMap */}
            {/* HairStyleMap  */}
            {/* HairstyleMap */}

            {BrandClicked &&
              (ProductNameFromOtherComponent === "Hair Style Try-on" ? (
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <h2 className="SelectHairStyle">Select Hair Style</h2>
                  </div>

                  <div className="demo-imagehairstyles-div">
                    {ImagesUrl.map((each_img, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() =>
                            each_img.imgsource === Blank
                              ? setBlankedIsClicked(true)
                              : getVirtualDataHairStyle(each_img.id)
                          }
                        >
                          <img
                            src={each_img.imgsource}
                            className="demo-imagehairstyles"
                            style={{
                              border:
                                each_img.id === HairStyleidSelected
                                  ? "5px solid  #9D6948"
                                  : "none",
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                // LipsBrandsDropdown
                // LipsBrandsDropdown
                // LipsBrandsDropdown
                <div>
                  <Dropdown
                    drop="down"
                    show={isDropDownOpen}
                    onClick={() => setIsDropDownOpen(!isDropDownOpen)}
                  >
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{
                        color: "4F3323",
                        backgroundColor: "#DDB892",
                        padding: "0.5rem",
                        borderRadius: "10px",
                        margin: "0.5rem",
                        border: "none",
                        fontSize: "1.2rem",
                      }}
                      className="choose-brand"
                    >
                      {brandSelected}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdDownMenuItems">
                      {DropdownMap()}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ))}

            {/* Mapping through Lips colors */}
            {/* Mapping through Lips colors */}
            {/* Mapping through  Lips colors */}

            <div
              style={{ display: "flex", overflowX: "scroll", maxWidth: "100%" }}
              className="colors-map-div"
            >
              {DemoProductChosen === "Lips" ||
              DemoProductName === "Blush" ||
              DemoProductName === "Lipstick & Lip Liner Combo"
                ? selectedBrand?.lipsColors.map((color, index) => (
                    <div key={index} style={{}}>
                      <div
                        key={color.colorName}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          padding: "1rem",
                        }}
                        className={
                          color?.id === colorClickedId
                            ? BlankIsClicked === true
                              ? "borderRemoved"
                              : "borderAdded"
                            : "borderRemoved"
                        }
                        onClick={() =>
                          color.colorName === "Blank"
                            ? setBlankedIsClicked(true)
                            : getVirtualColorForLipsAndHair(
                                color.colorHex,
                                color.colorName,
                                color.id,
                                selectedBrand.Name
                              )
                        }
                      >
                        {color.colorName === "Blank" ? (
                          <div></div>
                        ) : (
                          <div
                            style={{
                              backgroundColor: color.colorHex,
                              width: 50,
                              height: 50,
                              borderRadius: 100,
                            }}
                          ></div>
                        )}

                        <div>
                          {color.colorName === "Blank" && (
                            <MdOutlineBlock size={63} />
                          )}
                        </div>
                        <p style={{ textAlign: "center", marginTop: "1rem" }}>
                          {color.colorName}
                        </p>
                      </div>
                    </div>
                  ))
                : // HairColors mapping through
                  // HairColors mapping through
                  // HairColors mapping through
                  selectedBrand?.HairColors.map((color, index) => (
                    <div key={index}>
                      <div
                        key={color.colorName}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          padding: "1rem",
                        }}
                        className={
                          // color?.id === colorClickedId ? 'borderAdded' : 'borderRemoved'
                          color?.id === colorClickedId
                            ? BlankIsClicked === true
                              ? "borderRemoved"
                              : "borderAdded"
                            : "borderRemoved"
                        }
                        onClick={() =>
                          color.colorName === "Blank"
                            ? setBlankedIsClicked(true)
                            : getVirtualColorForLipsAndHair(
                                color.colorHex,
                                color.colorName,
                                color.id,
                                selectedBrand.Name
                              )
                        }
                      >
                        {color.colorName === "Blank" ? (
                          <div></div>
                        ) : (
                          <div
                            style={{
                              backgroundColor: color.colorHex,
                              width: 50,
                              height: 50,
                              borderRadius: 100,
                            }}
                          ></div>
                        )}
                        <div>
                          {color.colorName === "Blank" && (
                            <MdOutlineBlock size={60} />
                          )}
                        </div>
                        <p style={{ textAlign: "center", marginTop: "1rem" }}>
                          {color.colorName}
                        </p>
                      </div>
                    </div>
                  ))}
            </div>
          </div>

          {BrandClicked === false ? (
            ProductNameFromOtherComponent === "Hair Style Try-on" ? (
              <div
                style={{ width: "100%" }}
                className="Brand-Toggle-Button-Outside-of-Blur-For-hair"
              >
                <p>Almost there!</p>
                <p>Choose a Hair Style to get started</p>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h2 className="SelectHairStyle">Select Hair Style</h2>
                </div>
                <IoIosArrowRoundDown size={50} color="#503323" />

                <div className="demo-imagehairstyles-div">
                  {ImagesUrl.map((each_img, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() =>
                          each_img.imgsource === "Blank"
                            ? setBlankedIsClicked(true)
                            : getVirtualDataHairStyle(each_img.id)
                        }
                      >
                        <img
                          src={each_img.imgsource}
                          className="demo-imagehairstyles"
                          style={{
                            border:
                              each_img.id === HairStyleidSelected
                                ? "5px solid  #9D6948"
                                : "none",
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              // LipsBrandsDropdown
              // LipsBrandsDropdown
              // LipsBrandsDropdown
              <div className="Brand-Toggle-Button-Outside-of-Blur">
                <p>Almost there!</p>
                <p>Choose a brand to get started</p>
                <IoIosArrowRoundDown size={50} color="#503323" />

                <Dropdown
                  drop="down"
                  show={isDropDownOpen}
                  onClick={() => setIsDropDownOpen(!isDropDownOpen)}
                >
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="choose-brand"
                  >
                    {brandSelected}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdDownMenuItems">
                    {DropdownMap()}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )
          ) : null}
        </Modal.Body>
      </Modal>
    </div>
  );
}
