import React from "react";
import sliderimg1 from "../../assets/About/theresanaiforthat.png";
import sliderimg2 from "../../assets/About/killer.png";
import sliderimg3 from "../../assets/About/googlestart.png";
import inverstorImg from "../../assets/Home/Untitled design-7 (1).png";
import "../../App.css";

export default function AboutFeatured() {
  return (
    <div>
      <section
        className="section clients bg-gray"
        style={{ backgroundColor: "#F6EBEC" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-10 m-auto text-center">
              <h2 className="featured-div-h3">Featured In</h2>
              <div className="client-slider">
                <div className="item mb-4">
                  <img
                    className="m-auto"
                    src={sliderimg1}
                    alt="there-is-an-ai-for-that"
                  />
                </div>
                <div className="item mb-4">
                  <img
                    className="m-auto"
                    src={sliderimg2}
                    alt="there-is-an-ai-for-that"
                  />
                </div>
                <div className="item mb-4">
                  <img
                    className="m-auto"
                    src={sliderimg3}
                    alt="there-is-an-ai-for-that"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="section investors">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h2>Our Angel Investors</h2>
                <p>
                  Our mission to revolutionize AI solutions for diverse skin
                  shades and types is supported by a network of visionary angel
                  investors who share our commitment to innovation and
                  inclusivity.{" "}
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="block text-center">
                <div className="image shadow hover-zoom">
                  <img
                    className="m-auto"
                    src={inverstorImg}
                    alt="there-is-an-ai-for-that"
                  />
                </div>
                <h3>Google Ventures</h3>
                <p>Investor</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="block text-center">
                <div className="image shadow hover-zoom">
                  <img
                    className="m-auto"
                    src={inverstorImg}
                    alt="there-is-an-ai-for-that"
                  />
                </div>
                <h3>Gradient Ventures</h3>
                <p>Investor</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="block text-center">
                <div className="image shadow hover-zoom">
                  <img
                    className="m-auto"
                    src={inverstorImg}
                    alt="there-is-an-ai-for-that"
                  />
                </div>
                <h3>Hustle Fund</h3>
                <p>Investor</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="block text-center">
                <div className="image shadow hover-zoom">
                  <img
                    className="m-auto"
                    src={inverstorImg}
                    alt="there-is-an-ai-for-that"
                  />
                </div>
                <h3>Andreessen Horowitz</h3>
                <p>Investor</p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="cta-hire" style={{ backgroundColor: "#F6EBEC" }}>
        <div className="container Join-the-div">
          <div className="row">
            <div className="col-md-12 text-center join-text">
              <h2>Join the Inclusivity Revolution: Partner with yShade</h2>
              <p>
                Are you a beauty brand or SMB committed to inclusivity and
                innovation? Let's work together to create personalized
                experiences for diverse skin shades and types! Partner with
                yShade to leverage our cutting-edge AI technology and expertise,
                and let's revolutionize the beauty industry together. Reach out
                to us to explore collaboration opportunities and take the first
                step towards a more inclusive future.
              </p>
              <a href="/contactUs" className="mt-3 btn btn-main-md">
                Reach Out
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
