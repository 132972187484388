import React from "react";
import galleryImages from "../../assets/Home/carou2.png";
import { TfiAlarmClock } from "react-icons/tfi";
import { GiStairsGoal } from "react-icons/gi";
import { RiEmotionHappyLine } from "react-icons/ri";
import { PiConfetti } from "react-icons/pi";

export default function CareerLife() {
  return (
    <div>
      <section className="gallery">
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-md-4">
              <div className="image">
                <img
                  data-fancybox="gallery"
                  className="img-fluid"
                  src={galleryImages}
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="image">
                <img
                  data-fancybox="gallery"
                  className="img-fluid"
                  src={galleryImages}
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="image">
                <img
                  data-fancybox="gallery"
                  className="img-fluid"
                  src={galleryImages}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="company-fun-facts section">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="header">Life @ yShade</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="fun-fact">
                <i className="ti-timer career-icon">
                  <TfiAlarmClock
                    size={40}
                    style={{ marginBottom: "0.47rem" }}
                  />
                </i>
                <h3 className="header">Flexible hours</h3>
                <p>
                  Enjoy the freedom to create your own schedule and work at
                  times that suit you best.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="fun-fact">
                <i className="ti-filter career-icon">
                  <GiStairsGoal size={40} style={{ marginBottom: "0.47rem" }} />
                </i>
                <h3 className="header">Professional Development</h3>
                <p>
                  Grow your skills with access to online courses and conferences
                  that fuel your career growth.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="fun-fact">
                <i className="ti-game career-icon">
                  <RiEmotionHappyLine
                    size={40}
                    style={{ marginBottom: "0.47rem" }}
                  />
                </i>
                <h3 className="header">Wellness Support</h3>
                <p>
                  Prioritize your mental well-being with our self-care
                  initiatives.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="fun-fact">
                <i className="ti-hummer career-icon">
                  <PiConfetti size={40} style={{ marginBottom: "0.47rem" }} />
                </i>
                <h3 className="header">4 Working Days</h3>
                <p>
                  Recharge with an extra day off each week, and make the most of
                  your longer weekends.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
