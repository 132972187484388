import React from 'react'

export default function CareerHero() {
  return (
    <>
    <section class="section page-title">
	<div class="container">
		<div class="row">
			<div class="col-sm-8 m-auto">
				<h1>Careers</h1>
				<p>Help us revolutionize different industries with AI technology that celebrates diverse skin shades and types - explore our career opportunities and be part of the inclusivity revolution!</p>
			</div>
		</div>
	</div>
</section>

<section class="section career-featured pt-0">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="block">
					<div class="content">
						<h2>Who We're Looking For</h2>
						<p>We're seeking innovative thinkers, passionate problem-solvers, and dedicated individuals who share our vision of inclusivity and empowerment. If you're driven by a desire to make a positive impact and want to be part of a dynamic team shaping the future of AI technology, we want to hear from you!</p>
					</div>
					<div class="video">
						<img class="img-fluid shadow" src="/images/career/placeholder3.png" alt="video-thumbnail"/>
						<div class="video-button video-box">
							<a href="javascript:void(0)">
								<i class="ti-control-play" data-video="https://www.youtube.com/embed/AP2ukMD6dUM?autoplay=1"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
</>


  )
}
