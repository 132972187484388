export const brands = [
  {
    id: 1,
    Name: "Anastasia Beverly Hills",
    link: "https://www.anastasiabeverlyhills.com/products/deluxe-matte-lipstick-set",
    lipsColors: [
      {
        colorName: "Blank",
      },
      {
        colorName: "Peachy Nude",
        colorHex: "#BD6D54",
        id: 1,
      },

      {
        colorName: "Hush Rose",
        colorHex: "#AD575B",
        id: 2,
      },

      {
        colorName: "Royal Red",
        colorHex: "#AB1125",
        id: 3,
      },

      {
        colorName: "Blush Brown",
        colorHex: "#C36B62",
        id: 4,
      },
    ],

    HairColors: [
      {
        colorName: "Blank",
      },
      {
        colorName: "Saddle Brown",
        colorHex: "#8B4513",
        id: 1,
      },

      {
        colorName: "Golden Rod",
        colorHex: "#DAA520",
        id: 2,
      },

      {
        colorName: "Sea Green",
        colorHex: "#2E8B57",
        id: 3,
      },

      {
        colorName: "Indigo",
        colorHex: "#4B0082",
        id: 4,
      },
    ],
  },

  {
    id: 2,
    Name: "Bare Minerals",
    link: "https://www.bareminerals.com/products/mineralist-lasting-matte-liquid-lipstick?variant=40494128005205&nosto_source=cmp&nosto=675630234",
    lipsColors: [
      {
        colorName: "Blank",
      },
      {
        colorName: "Passionate",
        colorHex: "#99141C",
        id: 5,
      },

      {
        colorName: "Splendid",
        colorHex: "#A7616B",
        id: 6,
      },

      {
        colorName: "Lucky",
        colorHex: "#B46D62",
        id: 7,
      },

      {
        colorName: "Daring",
        colorHex: "#E10F15",
        id: 8,
      },

      {
        colorName: "Vivacious",
        colorHex: "#90113E",
        id: 9,
      },

      {
        colorName: "Determined",
        colorHex: "#A5533D",
        id: 10,
      },
    ],

    HairColors: [
      {
        colorName: "Blank",
      },
      {
        colorName: "Magenta",
        colorHex: "#FF00FF",
        id: 1,
      },

      {
        colorName: "Cyan",
        colorHex: "#00FFFF",
        id: 2,
      },

      {
        colorName: "Purple",
        colorHex: "#800080",
        id: 3,
      },

      {
        colorName: "Orange",
        colorHex: "#FFA500",
        id: 4,
      },
    ],
  },

  {
    id: 3,
    Name: "Charlotte Tibury",
    link: "https://www.charlottetilbury.com/us/product/matte-revolution-lost-cherry",
    lipsColors: [
      {
        colorName: "Blank",
      },
      {
        colorName: "Lost Cherry",
        colorHex: "#FF5E61",
        id: 12,
      },

      {
        colorName: "Sexy Sienna",
        colorHex: "#EF7468",
        id: 13,
      },

      {
        colorName: "Festival Magic",
        colorHex: "#650F1E",
        id: 14,
      },

      {
        colorName: "SO 90S",
        colorHex: "#832B16",
        id: 15,
      },
    ],

    HairColors: [
      {
        colorName: "Blank",
      },
      {
        colorName: "Dark Green",
        colorHex: "#008000",
        id: 1,
      },

      {
        colorName: "Navy",
        colorHex: "#000080",
        id: 2,
      },

      {
        colorName: "Maroon",
        colorHex: "#800000",
        id: 3,
      },

      {
        colorName: "Teal",
        colorHex: "#008080",
        id: 4,
      },
    ],
  },

  {
    id: 4,
    Name: "Clinique",
    link: "https://www.clinique.com/product/1605/60030/makeup/lipsticks/dramatically-differenttm-lipstick-shaping-lip-colour",
    lipsColors: [
      {
        colorName: "Blank",
      },
      {
        colorName: "Berry Freeze",
        colorHex: "#AA4E3D",
        id: 16,
      },

      {
        colorName: "Glazed Berry",
        colorHex: "#E77E87",
        id: 17,
      },

      {
        colorName: "Silvery Moon",
        colorHex: "#B6899C",
        id: 18,
      },

      {
        colorName: "Angel Red",
        colorHex: "#9E0A18",
        id: 19,
      },
    ],

    HairColors: [
      {
        colorName: "Blank",
      },
      {
        colorName: "Gray",
        colorHex: "#808080",
        id: 1,
      },

      {
        colorName: "Pink",
        colorHex: "#FFC0CB",
        id: 2,
      },

      {
        colorName: "Brown",
        colorHex: "#800000",
        id: 3,
      },

      {
        colorName: "White",
        colorHex: "#FFFFFF",
        id: 4,
      },
    ],
  },

  {
    id: 5,
    Name: "Dior",
    link: "https://www.dior.com/en_int/beauty/products/Y0291000-dior-addict-hydrating-shine-lipstick-90-natural-origin-ingredients-refillable?objectID=Y0291000&query=lipstick&queryID=e149f071a2345b778e9d04021a4e2a97",
    lipsColors: [
      {
        colorName: "Blank",
      },
      {
        colorName: "822 Scarlet Silk",
        colorHex: "#C63D2B",
        id: 20,
      },

      {
        colorName: "423 Romance",
        colorHex: "#9A645B",
        id: 21,
      },

      {
        colorName: "1947 Miss Dior",
        colorHex: "#BB5B5A",
        id: 22,
      },

      {
        colorName: "845 Vinyl Red",
        colorHex: "#C24B2B",
        id: 23,
      },

      {
        colorName: "536 Lucky",
        colorHex: "#E52131",
        id: 24,
      },
    ],

    HairColors: [
      {
        colorName: "Blank",
      },
      {
        colorName: "Black",
        colorHex: "#000000",
        id: 1,
      },

      {
        colorName: "Gold",
        colorHex: "#FFD700",
        id: 2,
      },

      {
        colorName: "Silver",
        colorHex: "#C0C0C0",
        id: 3,
      },

      {
        colorName: "Olive",
        colorHex: "#808000",
        id: 4,
      },
    ],
  },

  {
    id: 6,
    Name: "Glossier",
    link: "https://uk.glossier.com/products/ultralip?variant=41675661574191",
    lipsColors: [
      {
        colorName: "Blank",
      },
      {
        colorName: "Pony",
        colorHex: "#9C6169",
        id: 25,
      },

      {
        colorName: "Cranberry",
        colorHex: "#912E46",
        id: 26,
      },

      {
        colorName: "Fête",
        colorHex: "#D62E44",
        id: 27,
      },

      {
        colorName: "Ember",
        colorHex: "#481416",
        id: 28,
      },

      {
        colorName: "Vesper",
        colorHex: "#9F2544",
        id: 29,
      },
    ],

    HairColors: [
      {
        colorName: "Blank",
      },
      {
        colorName: "Purple",
        colorHex: "#800080",
        id: 1,
      },

      {
        colorName: "Green",
        colorHex: "#008000",
        id: 2,
      },

      {
        colorName: "Navy",
        colorHex: "#000080",
        id: 3,
      },

      {
        colorName: "Tomato",
        colorHex: "#FF6347",
        id: 4,
      },
    ],
  },

  {
    id: 7,
    Name: "Fenty Beauty",
    link: "https://fentybeauty.com/collections/lipstick/products/fenty-icon-the-fill-semi-matte-refillable-lipstick-summatime-collection-miss-candy-venom?variant=41524413333549",
    lipsColors: [
      {
        colorName: "Blank",
      },
      {
        colorName: "Miss Candy Venom",
        colorHex: "#E95699",
        id: 30,
      },

      {
        colorName: "Tropic Doll",
        colorHex: "#D5419E",
        id: 31,
      },

      {
        colorName: "Nosy Rosy",
        colorHex: "#F9423A",
        id: 32,
      },
    ],

    HairColors: [
      {
        colorName: "Blank",
      },
      {
        colorName: "Orange Red",
        colorHex: "#FF4500",
        id: 1,
      },

      {
        colorName: "Lawn Green",
        colorHex: "#7CFC00",
        id: 2,
      },

      {
        colorName: "Blue Violet",
        colorHex: "#8A2BE2",
        id: 3,
      },

      {
        colorName: "Lime Green",
        colorHex: "#32CD32",
        id: 4,
      },
    ],
  },

  {
    id: 8,
    Name: "Haus Labs",
    link: "https://www.hauslabs.com/products/atomic-shake-lip-lacquer?variant=39982746927192",
    lipsColors: [
      {
        colorName: "Blank",
      },
      {
        colorName: "Rose Shine",
        colorHex: "#D56277",
        id: 33,
      },

      {
        colorName: "Rosewood Shine",
        colorHex: "#A65A5B",
        id: 34,
      },

      {
        colorName: "Cherry Shine",
        colorHex: "#E7112A",
        id: 35,
      },

      {
        colorName: "Red Coral Shine",
        colorHex: "#FF474E",
        id: 36,
      },
    ],

    HairColors: [
      {
        colorName: "Blank",
      },
      {
        colorName: "Royal Blue",
        colorHex: "#4169E1",
        id: 1,
      },

      {
        colorName: "Spring Green",
        colorHex: "#00FF7F",
        id: 2,
      },

      {
        colorName: "Green Yellow",
        colorHex: "#ADFF2F",
        id: 3,
      },

      {
        colorName: "Crimson",
        colorHex: "#DC143C",
        id: 4,
      },
    ],
  },

  {
    id: 9,
    Name: "Huda Beauty",
    link: "https://hudabeauty.com/us/en_US/lips/power-bullet-cream-glow-sweet-nude-amore-HB00389.html",
    lipsColors: [
      {
        colorName: "Blank",
      },
      {
        colorName: "Sweet Nude Buttercup",
        colorHex: "#DE8878",
        id: 37,
      },

      {
        colorName: "Bossy Brown Self Mdade",
        colorHex: "#5B2E1D",
        id: 38,
      },

      {
        colorName: "Sweet Nude Habibi",
        colorHex: "#933F44",
        id: 39,
      },
    ],

    HairColors: [
      {
        colorName: "Blank",
      },
      {
        colorName: "Gold",
        colorHex: "#FFD700",
        id: 1,
      },

      {
        colorName: "Light Salmon",
        colorHex: "#FFA07A",
        id: 2,
      },

      {
        colorName: "Dark Turquoise",
        colorHex: "#00CED1",
        id: 3,
      },

      {
        colorName: "Medium Slate Blue",
        colorHex: "#7B68EE",
        id: 4,
      },
    ],
  },

  {
    id: 10,
    Name: "Ilia",
    link: "https://iliabeauty.com/products/wanderlust",
    lipsColors: [
      {
        colorName: "Blank",
      },
      {
        colorName: "Heartbeats",
        colorHex: "#AA1A21",
        id: 40,
      },

      {
        colorName: "Faded",
        colorHex: "#8A4436",
        id: 41,
      },

      {
        colorName: "Hold Me",
        colorHex: "#D77269",
        id: 42,
      },

      {
        colorName: "Lullaby",
        colorHex: "#B53952",
        id: 43,
      },
    ],

    HairColors: [
      {
        colorName: "Blank",
      },
      {
        colorName: "Light Coral",
        colorHex: "#F08080",
        id: 1,
      },

      {
        colorName: "Medium Turquoise",
        colorHex: "#48D1CC",
        id: 2,
      },

      {
        colorName: "Dark Olive Green",
        colorHex: "#556B2F",
        id: 3,
      },

      {
        colorName: "Dark Slate Gray",
        colorHex: "#2F4F4F",
        id: 4,
      },
    ],
  },

  {
    id: 11,
    Name: "Lancome",
    link: "https://www.lancome-usa.com/makeup/lips/lipstick/labsolu-rouge-cream-lipstick/00531-LAC.html?dwvar_00531-LAC_color=238%20Si%20Seulement",
    lipsColors: [
      {
        colorName: "Blank",
      },

      {
        colorName: "Si Seulement",
        colorHex: "#A66442",
        id: 44,
      },

      {
        colorName: "Rouge Badaboum",
        colorHex: "#9A2A2D",
        id: 45,
      },

      {
        colorName: "Paris S'eveille",
        colorHex: "#B5375C",
        id: 46,
      },

      {
        colorName: "Call Me Sienna",
        colorHex: "#FEB3B3",
        id: 47,
      },
    ],

    HairColors: [
      {
        colorName: "Blank",
      },
      {
        colorName: "Orchid",
        colorHex: "#DA70D6",
        id: 1,
      },

      {
        colorName: "Dark Khaki",
        colorHex: "#BDB76B",
        id: 2,
      },

      {
        colorName: "Dark Orchid",
        colorHex: "#9932CC",
        id: 3,
      },

      {
        colorName: "Hot Pink",
        colorHex: "#FF69B4",
        id: 4,
      },
    ],
  },

  {
    id: 12,
    Name: "MAC Cosmetics",
    link: "https://www.maccosmetics.com/product/13854/310/products/makeup/lips/lipstick/matte-lipstick?shade=Sweet_Deal",
    lipsColors: [
      {
        colorName: "Blank",
      },
      {
        colorName: "Sweet Deal",
        colorHex: "#BA584E",
        id: 48,
      },

      {
        colorName: "Rusian Red",
        colorHex: "#A30D19",
        id: 49,
      },

      {
        colorName: "Velvet Teddy",
        colorHex: "#D27A66",
        id: 50,
      },

      {
        colorName: "Diva",
        colorHex: "#7E2033",
        id: 51,
      },
    ],

    HairColors: [
      {
        colorName: "Blank",
      },
      {
        colorName: "Red",
        colorHex: "#FF0000",
        id: 1,
      },

      {
        colorName: "Green",
        colorHex: "#00FF00",
        id: 2,
      },

      {
        colorName: "Blue",
        colorHex: "#0000FF",
        id: 3,
      },

      {
        colorName: "Yellow",
        colorHex: "#FFFF00 ",
        id: 4,
      },
    ],
  },
];

export const KylieCostmetics = [
  {
    id: 13,
    Name: "Kylie Cosmetics",
    link: "https://kyliecosmetics.com/collections/kylie-cosmetics-lips-lip-liners/products/lip-liner?variant=43927620813042",
    lipsColors: [
      {
        colorName: "Blank",
      },

      {
        colorName: "Coconut",
        colorHex: "#BB7561",
        id: 1,
      },

      {
        colorName: "Caramel",
        colorHex: "#AB5E3D",
        id: 2,
      },

      {
        colorName: "Beau",
        colorHex: "#AD0A40",
        id: 3,
      },

      {
        colorName: "Iced Latte",
        colorHex: "#9D4E2E",
        id: 4,
      },

      {
        colorName: "Hot Chai",
        colorHex: "#935F46",
        id: 5,
      },
    ],
  },
];

export const RareBeauty = [
  {
    id: 14,
    Name: "Rare Beauty",
    link: "https://www.rarebeauty.com/products/soft-pinch-liquid-blush",
    lipsColors: [
      {
        colorName: "Blank",
      },

      {
        colorName: "Hope",
        colorHex: "#E09F84",
        id: 1,
      },

      {
        colorName: "Lucky",
        colorHex: "#F24B88",
        id: 2,
      },

      {
        colorName: "Grateful",
        colorHex: "#E0233F",
        id: 3,
      },

      {
        colorName: "Faith",
        colorHex: "#882D58",
        id: 4,
      },

      {
        colorName: "Grace",
        colorHex: "#B65A87",
        id: 5,
      },
    ],
  },
];
