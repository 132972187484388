import "./products.css";
import { useState } from "react";
import { initialTabs as tabs } from "./productsData";
import { motion, AnimatePresence } from "framer-motion";
import NavigationBar from "../General/Navbar/NavigationBar";
import Footer from "../General/Footer/Footer";
import hey from "../../assets/Home/iphone_frame.webp";

export default function Robomua() {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  return (
    <>
      <div className="robo-main-div">
        <div className="window">
          <NavigationBar />
          <nav className="robomua-products-nav">
            <ul>
              {tabs.map((item) => (
                <li
                  key={item.label}
                  className={item === selectedTab ? "selected" : ""}
                  onClick={() => setSelectedTab(item)}
                >
                  <div className="icon-label-div">
                    <span> {<item.icon />}</span>
                    <span>{item.label}</span>
                  </div>

                  {item === selectedTab ? (
                    <motion.div className="underline" layoutId="underline" />
                  ) : null}
                </li>
              ))}
            </ul>
          </nav>
          <div className="products-data">
            <AnimatePresence mode="wait">
              <div
                key={selectedTab ? selectedTab.label : "empty"}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <selectedTab.ProductPage />
              </div>
            </AnimatePresence>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
